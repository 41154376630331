import { Axios, ApiUrl } from './config';

const TOKEN_KEY = 'fmit';
const REFRESH_TOKEN_KEY = 'fmirt';

export const signInApi = async (email, password) => {
  const res = await Axios.post(`${ApiUrl}/auth/token/`, {
    email,
    password
  });
  return res;
};

export const setAccessToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getAccessToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const deleteAccessToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const setRefreshToken = (refresh_token) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const deleteRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const initAxiosInterceptors = () => {
  Axios.interceptors.request.use(function (config) {
    const token = getAccessToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      return new Promise((resolve, reject) => {
        const originalReq = err.config;
        if (
          err.response.status === 401 &&
          err.config &&
          !err.config.__isRetryRequest
        ) {
          originalReq._retry = true;
          deleteAccessToken();

          let res = fetch(`${ApiUrl}/auth/token/refresh/`, {
            method: 'POST',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({ refresh: getRefreshToken() })
          })
            .then((res) => res.json())
            .then((res) => {
              if (res.access) {
                setAccessToken(res.access);
                originalReq.headers.Authorization = `Bearer ${res.access}`;
                return Axios(originalReq);
              }
              window.location.reload(false);
              deleteRefreshToken();
              return Promise.reject(err);
            })
            .catch((err) => {
              console.error(err.message);
              deleteRefreshToken();
              window.location.reload(false);
              return Promise.reject(err);
            });

          resolve(res);
        }
        return Promise.reject(err);
      });
    }
  );
};
