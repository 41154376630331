export const eventsActionTypes = {
  SET_EVENTS: 'SET_EVENTS',
  SET_EDIT_EVENT: 'SET_EDIT_EVENT',
  SET_MODAL_EVENT_IS_OPEN: 'SET_MODAL_EVENT_IS_OPEN'
};

export const setEvents = (events) => {
  return {
    type: eventsActionTypes.SET_EVENTS,
    payload: { events }
  };
};

export const setEditEvent = (editEvent) => {
  return {
    type: eventsActionTypes.SET_EDIT_EVENT,
    payload: { editEvent }
  };
};

export const setModalEventIsOpen = (modalEventIsOpen) => {
  return {
    type: eventsActionTypes.SET_MODAL_EVENT_IS_OPEN,
    payload: { modalEventIsOpen }
  };
};
