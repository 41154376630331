import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import moment from 'moment';

registerLocale('es', es);

export const FormGroup = styled.div`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}rem` : '1rem'};

  &.text_center {
    text-align: center;
  }

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Field = ({ type, label, name, error, ...props }) => {
  return (
    <>
      {label && <Label htmlFor={`id_${name}`}>{label}:</Label>}
      {['text', 'number', 'email', 'password', 'file'].includes(type) && (
        <Input type={type} name={name} id={`id_${name}`} {...props} />
      )}
      {type === 'textarea' && (
        <TextArea name={name} id={`id_${name}`} {...props} />
      )}
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

export const DatePicker = ({ type, label, name, value, onChange, error }) => {
  const handleOnChange = (date) => {
    onChange({ target: { name, value: date } });
  };

  return (
    <>
      {label && <Label htmlFor={`id_${name}`}>{label}:</Label>}
      <ReactDatePicker
        locale='es'
        type={type}
        name={name}
        id={`id_${name}`}
        selected={value}
        onChange={handleOnChange}
        customInput={<Input />}
        todayButton='Hoy'
        dateFormat='yyyy-MM-dd'
        autoComplete='off'
        showYearDropdown
        showMonthDropdown
      />
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

export const Select = ({
  name,
  label,
  value,
  creatable,
  onChange,
  error,
  options,
  ...props
}) => {
  const [originalValue, setOriginalValue] = useState();

  useEffect(() => {
    if (creatable && value) {
      value = value.map((v) => ({ label: v.toLowerCase, value: v }));
    }
  }, [value]);

  useEffect(() => {
    if (value && !creatable) {
      setOriginalValue(options.find((o) => o.value === value));
    }
  }, []);

  const handleOnChange = (value) => {
    setOriginalValue(value);
    let event = {};

    if (creatable && value && value.length > 0) {
      const values = value.map((v) => v.value);
      event = { target: { value: values, name } };
    } else {
      event = { target: { value: value.value, name } };
    }

    onChange(event);
  };

  return (
    <>
      {label && <Label htmlFor={`id_${name}`}>{label}:</Label>}
      {creatable ? (
        <CreatableSelect
          value={originalValue}
          inputId={`id_${name}`}
          onChange={handleOnChange}
          options={options}
          {...props}
        />
      ) : (
        <ReactSelect
          value={originalValue}
          inputId={`id_${name}`}
          onChange={handleOnChange}
          options={options}
          placeholder='Seleccionar...'
          noOptionsMessage={() => 'Sin opciones'}
          {...props}
        />
      )}
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

export const Label = styled.div`
  font-size: 0.8em;
  color: var(--color-dark);
  padding-left: 3px;
  padding-bottom: 0.3rem;
  display: block;
`;

export const Input = styled.input`
  font-size: 1rem;
  font-weight: normal;
  color: var(--color-dark);
  margin: 0;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
  transition: border 0.3s ease 0s;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  height: var(--components-height);
  background-color: var(--color-light);

  &::placeholder {
    color: var(--color-gray);
  }

  &:focus {
    outline: none;
  }

  &:-internal-autofill-selected {
    background-color: transparent;
  }
`;

export const TextArea = styled.textarea`
  font-size: 1rem;
  font-weight: normal;
  color: var(--color-dark);
  margin: 0;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
  transition: border 0.3s ease 0s;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--color-light);
  min-height: 130px;
  resize: none;

  &::placeholder {
    color: var(--color-gray);
  }

  &:focus {
    outline: none;
  }

  &:-internal-autofill-selected {
    background-color: transparent;
  }
`;

export const Checkbox = ({ type, label, name, value, error, ...props }) => {
  return (
    <>
      <label htmlFor={`id_${name}`}>
        <input
          type={type || 'checkbox'}
          name={name}
          id={`id_${name}`}
          value={value}
          checked={value}
          {...props}
        />{' '}
        {label}
      </label>
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

export const ErrorText = styled.div`
  color: var(--color-danger);
  font-size: 0.8rem;
  margin: 0.2rem 0 0.5rem 0.2rem;
`;

export const InputFile = ({ name, ...props }) => {
  return (
    <InputFileWrap>
      <input type='file' name={name} id={`id_${name}`} {...props} />
      <label htmlFor={`id_${name}`}>
        <span>
          <i className='fa fa-plus'></i> Seleccionar imagen
        </span>
      </label>
    </InputFileWrap>
  );
};

const InputFileWrap = styled.div`
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    &:focus + label,
    & + label:hover {
      background-color: white;
    }

    &:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
  }

  label {
    font-size: 0.7rem;
    font-weight: 700;
    color: colorGray;
    background-color: colorGrayLight;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100px;
    height: 100px;
    border: 2px dashed colorGray;
    border-radius: 5px;

    span {
      i {
        display: block;
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
    }

    * {
      pointer-events: none;
    }
  }
`;
