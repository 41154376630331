import React from 'react';
import { connect } from 'react-redux';

import {
  Card,
  CardBody,
  Group,
  GridDefault,
  Title,
  Button,
  FormGroup,
  Input
} from '../ui';
import { setEditEvent, setModalEventIsOpen } from '../../redux/actions/events';
import EventModal from './EventModal';
import EventList from './EventList';

const EventsPanel = ({
  animals,
  animal,
  setEditEvent,
  setModalEventIsOpen
}) => {
  const handleClickAdd = () => {
    setEditEvent(null);
    setModalEventIsOpen(true);
  };

  return (
    <>
      <GridDefault>
        <Card>
          <CardBody>
            <Group marginBottom={2}>
              <Title Tag='h4' margin='md'>
                Eventos
              </Title>
              <Button
                className='success fluid rounded'
                onClick={handleClickAdd}
              >
                + Agregar
              </Button>
            </Group>

            <div>
              <FormGroup>
                <Input type='text' placeholder='Buscar' />
              </FormGroup>
            </div>
          </CardBody>
        </Card>

        {animal && (
          <div>
            <EventList animal={animal} />
          </div>
        )}
      </GridDefault>
      <EventModal animals={animals} animal={animal} />
    </>
  );
};

const mapDispatchToProps = {
  setEditEvent,
  setModalEventIsOpen
};

export default connect(null, mapDispatchToProps)(EventsPanel);
