import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';

import {
  Grid,
  FormGroup,
  Select,
  DatePicker,
  Field,
  FlexBox,
  Button
} from '../ui';
import {
  setWeight,
  setTypeProduction,
  setModalProductionIsOpen
} from '../../redux/actions/production';
import { createWeightApi, updateWeightApi } from '../../api';
import useForm from '../../hooks/useForm';

const weightSchema = Yup.object().shape({
  date: Yup.date()
    .typeError('Ingresa una fecha válida')
    .required('Fecha requerida'),
  animal: Yup.number().required(),
  weight: Yup.number().typeError('Ingresa el peso'),
  diet: Yup.string().max(100),
  notes: Yup.string()
});

const dietOptions = [
  { value: 'pastoreo', label: 'Pastoreo' },
  { value: 'pastoreo_concentrado', label: 'Pastoreo + Concentrado' },
  { value: 'suplemento', label: 'Suplemento' },
  { value: 'estabulacion', label: 'Estabulación' },
  { value: 'semi_estabulacion', label: 'Semi-Estabulación' },
  { value: 'otros', label: 'Otros' }
];

const WeightForm = ({
  animal,
  weightList,
  setWeight,
  editProduction,
  setModalProductionIsOpen,
  setTypeProduction
}) => {
  const [loading, setLoading] = useState(false);
  let initialValues = {
    date: editProduction
      ? new Date(moment(editProduction.date).format())
      : new Date(),
    animal: editProduction ? editProduction.animal : animal.id,
    weight: editProduction ? editProduction.weight : '',
    diet: editProduction ? editProduction.diet : '',
    notes: editProduction ? editProduction.notes : ''
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      if (!editProduction) {
        const res = await createWeightApi(values);
        setWeight([res.data, ...weightList]);
        toast.success('Registro creado correctamente');
        formHook.resetValues();
      } else {
        const res = await updateWeightApi(editProduction.id, values);
        setWeight(
          weightList.map((i) => {
            if (i.id === editProduction.id) return res.data;
            return i;
          })
        );
        toast.success('Registro modificado correctamente');
      }
      setModalProductionIsOpen(false);
    } catch (error) {
      console.log(error.response);
      toast.error(error);
      setLoading(false);
    }
  };

  const formHook = useForm({
    initialValues,
    validationSchema: weightSchema,
    onSubmit: handleOnSubmit
  });

  const handleGoBack = () => {
    setTypeProduction(null);
  };

  return (
    <>
      <FlexBox alignItems='center' marginBottom='2rem'>
        <Button className='go_back' onClick={handleGoBack}>
          <i className='mdi mdi-chevron-left' />
        </Button>
        <h5>{editProduction && 'Modificar '}Registro de peso</h5>
      </FlexBox>
      <form onSubmit={formHook.handleSubmit}>
        <FormGroup>
          <DatePicker
            label='Fecha'
            name='date'
            value={formHook.values.date}
            onChange={formHook.handleChange}
            error={formHook.errors.date}
          />
        </FormGroup>
        <Grid cols={2}>
          <FormGroup>
            <Field
              type='number'
              label='Peso en kilos'
              name='weight'
              value={formHook.values.weight}
              onChange={formHook.handleChange}
              error={formHook.errors.weight}
            />
          </FormGroup>
          <FormGroup>
            <Select
              name='diet'
              label='Dieta'
              value={formHook.values.diet}
              onChange={formHook.handleChange}
              error={formHook.errors.diet}
              options={dietOptions}
            />
          </FormGroup>
        </Grid>
        <FormGroup>
          <Field
            type='textarea'
            label='Notas'
            name='notes'
            value={formHook.values.notes}
            onChange={formHook.handleChange}
            error={formHook.errors.notes}
          />
        </FormGroup>
        <FormGroup className='center'>
          <Button type='submit' className='success rounded' loading={loading}>
            {editProduction ? 'Modificar' : 'Añadir'}
          </Button>
        </FormGroup>
        <FormGroup className='text_center' marginBottom='0'>
          <Button
            className='link'
            onClick={() => setModalProductionIsOpen(false)}
          >
            Cerrar modal
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  weightList: state.production.weight,
  editProduction: state.production.editProduction
});

const mapDispatchToProps = {
  setWeight,
  setTypeProduction,
  setModalProductionIsOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(WeightForm);
