import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  MenuDots,
  List,
  ListTitle,
  ListHead,
  ListRow,
  ListCol,
  DateFormat,
  Spinner
} from '../ui';
import {
  setMilk,
  setModalProductionIsOpen,
  setTypeProduction,
  setEditProduction
} from '../../redux/actions/production';
import { getMilkListApi, deleteMilkApi } from '../../api';

const MilkList = ({
  animal,
  milk,
  setMilk,
  setModalProductionIsOpen,
  setTypeProduction,
  setEditProduction
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMilkList();
  }, [animal]);

  const getMilkList = async () => {
    try {
      const res = await getMilkListApi(`?animal=${animal.id}`);
      setMilk(res.data.results);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleClickEdit = (type, data) => {
    setModalProductionIsOpen(true);
    setTypeProduction(type);
    setEditProduction(data);
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteMilkApi(id);
      if (res.status == 204) {
        setMilk(milk.filter((i) => i.id !== id));
        toast.success('Registro eliminado correctamente');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <List>
      <ListTitle>
        <h5>Registros de leche</h5>
      </ListTitle>
      <ListHead>
        <ListCol flex='15%'>Fecha</ListCol>
        <ListCol flex='35%'>Jornada</ListCol>
        <ListCol flex='25%'>Kilos de concentrado</ListCol>
        <ListCol flex='20%'>Litros de leche</ListCol>
      </ListHead>
      {loading ? (
        <Spinner />
      ) : milk && milk.length > 0 ? (
        milk.map((m) => (
          <ListRow key={m.id}>
            <ListCol flex='15%'>
              <DateFormat date={m.date} />
            </ListCol>
            <ListCol flex='35%'>{m.time_display}</ListCol>
            <ListCol flex='25%'>{m.kg_concentrated}</ListCol>
            <ListCol flex='20%'>{m.lt_milk}</ListCol>
            <ListCol>
              <MenuDots>
                <span onClick={() => handleClickEdit('milk', m)}>Editar</span>
                <span onClick={() => handleDelete(m.id)}>Eliminar</span>
              </MenuDots>
            </ListCol>
          </ListRow>
        ))
      ) : (
        <ListRow className='no-results'>No hay leche registrada.</ListRow>
      )}
    </List>
  );
};

const mapStateToProps = (state) => ({
  milk: state.production.milk
});

const mapDispatchToProps = {
  setMilk,
  setModalProductionIsOpen,
  setTypeProduction,
  setEditProduction
};

export default connect(mapStateToProps, mapDispatchToProps)(MilkList);
