import React from 'react';
import GlobalStyle from '../ui/GlobalStyle';
import { ToastContainer } from 'react-toastify';

import Header from './Header';

const Base = ({ children, hookSetUser }) => {
  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <Header hookSetUser={hookSetUser} />
      <main>{children}</main>
    </>
  );
};

export default Base;
