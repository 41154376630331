import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { setUser } from '../redux/actions/auth';
import { setAccessToken, setRefreshToken, signInApi, getMeApi } from '../api';
import useForm from '../hooks/useForm';
import {
  Container,
  Card,
  CardBody,
  FormGroup,
  Field,
  Button,
  Title,
  Spinner
} from '../components/ui';

const loginSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().min(8).required()
});

const Login = ({ history, setUser, hookSetUser }) => {
  const [sending, setSending] = useState(false);

  const initialValues = {
    email: '',
    password: ''
  };

  const handleOnSubmit = async (values) => {
    setSending(true);

    try {
      const res = await signInApi(values.email, values.password);

      if (res.status == 200 && res.data.access) {
        const { access: token, refresh: refreshToken } = res.data;
        const userRes = await getMeApi(token);
        setUser(userRes.data);
        hookSetUser(userRes.data);
        history.push('/');
        setAccessToken(token);
        setRefreshToken(refreshToken);
      } else {
        toast.error(res.data.message);
        setSending(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error('El correo y/o la contraseña son incorrectos.');
      } else {
        toast.error(error.message);
      }
      setSending(false);
    }
  };

  const formHook = useForm({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: handleOnSubmit
  });

  return (
    <Container size='sm'>
      <Card>
        <CardBody>
          <Title Tag='h1' margin='sm'>
            Ingresar
          </Title>

          <form onSubmit={formHook.handleSubmit} noValidate>
            <FormGroup>
              <Field
                type='email'
                label='Correo electrónico'
                name='email'
                value={formHook.values.email}
                onChange={formHook.handleChange}
                error={formHook.errors.email}
              />
            </FormGroup>
            <FormGroup>
              <Field
                type='password'
                label='Contraseña'
                name='password'
                value={formHook.values.password}
                onChange={formHook.handleChange}
                error={formHook.errors.password}
                formGroup
              />
            </FormGroup>
            <FormGroup textCenter>
              <Button
                type='submit'
                className='primary rounded'
                loading={sending}
              >
                {sending ? (
                  <>
                    <Spinner /> Ingresando
                  </>
                ) : (
                  'Ingresar'
                )}
              </Button>
            </FormGroup>
          </form>
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

const mapDispatchToProps = {
  setUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
