import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { setUser } from '../redux/actions/auth';
import { initAxiosInterceptors } from '../api';
import useAuth from '../hooks/useAuth';

import Layout from '../components/layout/Base';
import { ScreenLoading } from '../components/ui';
import LogoutRoutes from './LogoutRoutes';
import LoginRoutes from './LoginRoutes';

initAxiosInterceptors();

const App = ({ setUser }) => {
  const { user, setUser: hookSetUser, loadingUser } = useAuth();

  useEffect(() => {
    if (!loadingUser) setUser(user);
  }, [loadingUser]);

  if (loadingUser) {
    return <ScreenLoading />;
  }

  return (
    <BrowserRouter>
      <Layout hookSetUser={hookSetUser}>
        {user ? <LoginRoutes /> : <LogoutRoutes hookSetUser={hookSetUser} />}
      </Layout>
    </BrowserRouter>
  );
};

const mapDispatchToProps = {
  setUser
};

export default connect(null, mapDispatchToProps)(App);
