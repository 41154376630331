import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid var(--color-light);

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }
  }
`;
