import React from 'react';

const NotFound = () => (
  <>
    <h1>404</h1>
    <h2>Page not found</h2>
  </>
);

export default NotFound;
