import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  MenuDots,
  List,
  ListTitle,
  ListHead,
  ListRow,
  ListCol,
  DateFormat,
  Spinner
} from '../ui';
import {
  setEvents,
  setModalEventIsOpen,
  setEditEvent
} from '../../redux/actions/events';
import { getEventsApi, deleteEventApi } from '../../api';

const EventList = ({
  animal,
  events,
  setEvents,
  setModalEventIsOpen,
  setEditEvent
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEventList();
  }, [animal]);

  const getEventList = async () => {
    try {
      const res = await getEventsApi(`?animal=${animal.id}`);
      setEvents(res.data.results);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleClickEdit = (data) => {
    setModalEventIsOpen(true);
    setEditEvent(data);
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteEventApi(id);
      if (res.status == 204) {
        setEvents(events.filter((i) => i.id !== id));
        toast.success('Evento eliminado correctamente');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <List>
      <ListTitle>
        <h5>Eventos registrados</h5>
      </ListTitle>
      <ListHead>
        <ListCol flex='15%'>Fecha</ListCol>
        <ListCol flex='35%'>Nombre del evento</ListCol>
        <ListCol flex='10%'>Repetir</ListCol>
        <ListCol flex='35%'>Notas</ListCol>
      </ListHead>
      {loading ? (
        <Spinner />
      ) : events && events.length > 0 ? (
        events.map((event) => (
          <ListRow key={event.id}>
            <ListCol flex='15%'>
              <DateFormat date={event.date} />
            </ListCol>
            <ListCol flex='35%'>{event.name}</ListCol>
            <ListCol flex='10%'>{event.repeat}</ListCol>
            <ListCol flex='35%'>{event.notes}</ListCol>
            <ListCol>
              <MenuDots>
                <span onClick={() => handleClickEdit(event)}>Editar</span>
                <span onClick={() => handleDelete(event.id)}>Eliminar</span>
              </MenuDots>
            </ListCol>
          </ListRow>
        ))
      ) : (
        <ListRow className='no-results'>No hay eventos registrados.</ListRow>
      )}
    </List>
  );
};

const mapStateToProps = (state) => ({
  events: state.events.events
});

const mapDispatchToProps = {
  setEvents,
  setModalEventIsOpen,
  setEditEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
