import React from 'react';
import { connect } from 'react-redux';

import {
  Card,
  CardBody,
  Filters,
  Group,
  GridDefault,
  Title,
  Button,
  FormGroup,
  Input
} from '../ui';
import {
  setEditHealth,
  setTypeHealth,
  setModalHealthIsOpen
} from '../../redux/actions/health';
import HealthModal from './HealthModal';
import TreatmentList from './TreatmentList';
import VaccineList from './VaccineList';
import DeathList from './DeathList';

const HealthPanel = ({
  animals,
  animal,
  setEditHealth,
  setTypeHealth,
  setModalHealthIsOpen
}) => {
  const handleClickAdd = () => {
    setTypeHealth(null);
    setEditHealth(null);
    setModalHealthIsOpen(true);
  };

  return (
    <>
      <GridDefault>
        <Card>
          <CardBody>
            <Group marginBottom={2}>
              <Title Tag='h4' margin='md'>
                Datos veterinarios
              </Title>
              <Button
                className='success fluid rounded'
                onClick={handleClickAdd}
              >
                + Agregar
              </Button>
            </Group>

            <div>
              <FormGroup>
                <Input type='text' placeholder='Buscar' />
              </FormGroup>

              <Filters>
                <h6 className='mb'>FILTRAR</h6>
                <ul>
                  <li>
                    <a>
                      <i className='mdi mdi-eye'></i> Todo
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className='mdi mdi-eye'></i> Tratamientos
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className='mdi mdi-eye'></i> Vacunas
                    </a>
                  </li>
                </ul>
              </Filters>
            </div>
          </CardBody>
        </Card>

        {animal && (
          <div>
            <TreatmentList animal={animal} />
            <VaccineList animal={animal} />
            <DeathList animal={animal} />
          </div>
        )}
      </GridDefault>
      <HealthModal animals={animals} animal={animal} />
    </>
  );
};

const mapDispatchToProps = {
  setEditHealth,
  setTypeHealth,
  setModalHealthIsOpen
};

export default connect(null, mapDispatchToProps)(HealthPanel);
