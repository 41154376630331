import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  MenuDots,
  List,
  ListTitle,
  ListHead,
  ListRow,
  ListCol,
  DateFormat,
  Spinner
} from '../ui';
import {
  setInseminations,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck,
  setEditReproduction
} from '../../redux/actions/reproduction';
import { getInseminationsApi, deleteInseminationApi } from '../../api';

const InseminationList = ({
  animal,
  inseminations,
  setInseminations,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck,
  setEditReproduction
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInseminations();
  }, [animal]);

  const getInseminations = async () => {
    try {
      const res = await getInseminationsApi(`?animal=${animal.id}`);
      setInseminations(res.data.results);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleClickEdit = (type, data) => {
    setModalReproductionIsOpen(true);
    setTypeReproductiveCheck(type);
    setEditReproduction(data);
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteInseminationApi(id);
      if (res.status == 204) {
        setInseminations(inseminations.filter((i) => i.id !== id));
        toast.success('Registro eliminado correctamente');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <List>
      <ListTitle>
        <h5>Inseminaciones</h5>
      </ListTitle>
      <ListHead>
        <ListCol flex='15%'>Fecha</ListCol>
        <ListCol flex='20%'>Tipo</ListCol>
        <ListCol flex='65%'>Toro</ListCol>
      </ListHead>
      {loading ? (
        <Spinner />
      ) : inseminations && inseminations.length > 0 ? (
        inseminations.map((insemination) => (
          <ListRow key={insemination.id}>
            <ListCol flex='15%'>
              <DateFormat date={insemination.date} />
            </ListCol>
            <ListCol flex='20%'>{insemination.type_of_display}</ListCol>
            <ListCol flex='60%'>
              {insemination.bull ? insemination.bull_display.name : '-'}{' '}
              <small>
                &nbsp; (Hace&nbsp;
                <DateFormat date={insemination.date} ageString />)
              </small>
            </ListCol>
            <ListCol>
              <MenuDots>
                <span
                  onClick={() => handleClickEdit('insemination', insemination)}
                >
                  Editar
                </span>
                <span onClick={() => handleDelete(insemination.id)}>
                  Eliminar
                </span>
              </MenuDots>
            </ListCol>
          </ListRow>
        ))
      ) : (
        <ListRow className='no-results'>
          No hay inseminaciones registradas.
        </ListRow>
      )}
    </List>
  );
};

const mapStateToProps = (state) => ({
  inseminations: state.reproduction.inseminations
});

const mapDispatchToProps = {
  setInseminations,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck,
  setEditReproduction
};

export default connect(mapStateToProps, mapDispatchToProps)(InseminationList);
