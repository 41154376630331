import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Container,
  GridDefault,
  FlexBox,
  Group,
  FormGroup,
  Input,
  Modal,
  Card,
  CardBody,
  Button,
  Title,
  Filters,
  Spinner
} from '../components/ui';
import { getAnimalsApi } from '../api';
import AnimalForm from '../components/animals/AnimalForm';
import AnimalItem from '../components/animals/AnimalItem';

const Animals = ({ animals }) => {
  const [animalsList, setAnimalsList] = useState(
    animals.filter(
      (animal) => animal.status !== 'death' && animal.status !== 'sold'
    )
  );
  const [loadingAnimals, setLoadingAnimals] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterSex, setFilterSex] = useState('');

  const getAnimals = async (query = null, filterS = filterStatus) => {
    setLoadingAnimals(true);
    try {
      const res = await getAnimalsApi(query);
      if (filterS === '') {
        setAnimalsList(
          res.data.results.filter(
            (animal) => animal.status !== 'death' && animal.status !== 'sold'
          )
        );
      } else {
        setAnimalsList(res.data.results);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoadingAnimals(false);
  };

  const showModal = () => {
    setModalIsOpen(true);
  };

  const handleChangeSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.length > 1) {
      getAnimals(
        `search=${query}&status=${
          filterStatus !== '' ? filterStatus : ''
        }&sex=${filterSex}`
      );
    } else if (query.length === 0) {
      getAnimals();
    }
  };

  const handleFilterStatus = async (e, query) => {
    e.preventDefault();
    setFilterStatus(query);
    if (query) {
      getAnimals(
        `search=${searchQuery}&status=${query}&sex=${filterSex}`,
        query
      );
    } else {
      setSearchQuery('');
      setFilterStatus('');
      setFilterSex('');
      getAnimals(null, '');
    }
  };

  const handleFilterSex = (e, sex) => {
    e.preventDefault();
    setFilterSex(sex);
    getAnimals(
      `search=${searchQuery}&status=${
        filterStatus !== '' ? filterStatus : ''
      }&sex=${sex}`
    );
  };

  return (
    <>
      <Container>
        <GridDefault>
          <Card className='sm light'>
            <CardBody>
              <Group marginBottom={2}>
                <Title Tag='h4' margin='md'>
                  Animales
                </Title>
                <Button className='success fluid rounded' onClick={showModal}>
                  + Agregar
                </Button>
              </Group>
              <div>
                <FormGroup>
                  <Input
                    type='text'
                    placeholder='Buscar'
                    value={searchQuery}
                    onChange={handleChangeSearch}
                  />
                </FormGroup>
                <Filters>
                  <h6 className='mb'>FILTRAR</h6>
                  <ul>
                    <li>
                      <a
                        href='#'
                        className={filterStatus === '' ? 'active' : ''}
                        onClick={(e) => handleFilterStatus(e, '')}
                      >
                        <i className='mdi mdi-eye'></i> Todos los animales
                      </a>
                    </li>
                    <li>
                      <a
                        href='#'
                        className={
                          filterStatus === 'on_the_farm' ? 'active' : ''
                        }
                        onClick={(e) => handleFilterStatus(e, 'on_the_farm')}
                      >
                        <i className='mdi mdi-eye'></i> En la finca
                      </a>
                    </li>
                    <li>
                      <a
                        href='#'
                        className={filterStatus === 'on_sale' ? 'active' : ''}
                        onClick={(e) => handleFilterStatus(e, 'on_sale')}
                      >
                        <i className='mdi mdi-eye'></i> En Venta
                      </a>
                    </li>
                    <li>
                      <a
                        href='#'
                        className={filterStatus === 'sold' ? 'active' : ''}
                        onClick={(e) => handleFilterStatus(e, 'sold')}
                      >
                        <i className='mdi mdi-eye'></i> Vendidos
                      </a>
                    </li>
                    <li>
                      <a
                        href='#'
                        className={filterStatus === 'death' ? 'active' : ''}
                        onClick={(e) => handleFilterStatus(e, 'death')}
                      >
                        <i className='mdi mdi-eye'></i> Fallecidos
                      </a>
                    </li>
                    <li>
                      <a
                        href='#'
                        className={filterSex === 1 ? 'active' : ''}
                        onClick={(e) => handleFilterSex(e, 1)}
                      >
                        <i className='mdi mdi-eye'></i> Hembras
                      </a>
                    </li>
                    <li>
                      <a
                        href='#'
                        className={filterSex === 2 ? 'active' : ''}
                        onClick={(e) => handleFilterSex(e, 2)}
                      >
                        <i className='mdi mdi-eye'></i> Machos
                      </a>
                    </li>
                  </ul>
                </Filters>
              </div>
            </CardBody>
          </Card>
          <div>
            <Card className='sm light'>
              <CardBody>
                <FlexBox justifyContent='space-between' alignItems='center'>
                  <h5 style={{ marginBottom: 0 }}>Listado</h5>
                  <small>
                    Total de animales: <strong>{animalsList.length}</strong>
                  </small>
                </FlexBox>
              </CardBody>
            </Card>
            {!loadingAnimals ? (
              animalsList.length > 0 ? (
                animalsList.map((animal) => (
                  <AnimalItem key={animal.id} animal={animal} />
                ))
              ) : (
                <p>No hay animales registrados.</p>
              )
            ) : (
              <Spinner center text='Cargando información' showText />
            )}
          </div>
        </GridDefault>
      </Container>
      <Modal
        title='Agregar animal'
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      >
        <AnimalForm setModalIsOpen={setModalIsOpen} />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  animals: state.farms.animals
});

export default connect(mapStateToProps, null)(Animals);
