import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';

import {
  Grid,
  FormGroup,
  Select,
  DatePicker,
  Field,
  FlexBox,
  Button
} from '../ui';
import {
  setCheckups,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck
} from '../../redux/actions/reproduction';
import { createCheckupApi, updateCheckupApi } from '../../api';
import useForm from '../../hooks/useForm';

const checkupSchema = Yup.object().shape({
  date: Yup.date()
    .typeError('Ingresa una fecha válida.')
    .required('Fecha requerida.'),
  animal: Yup.number().required(),
  status: Yup.number()
    .typeError('Estado requerido.')
    .required('Estado requerido.'),
  months: Yup.number()
    .typeError('Los meses deben ser un número')
    .transform((value) => (isNaN(value) ? null : value))
    .nullable(true),
  notes: Yup.string()
});

const statusOptions = [
  { value: 1, label: 'Cargada' },
  { value: 2, label: 'No cargada' }
];

const CheckupForm = ({
  animal,
  checkups,
  setCheckups,
  editReproduction,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck
}) => {
  const [loading, setLoading] = useState(false);
  let initialValues = {
    date: editReproduction
      ? new Date(moment(editReproduction.date).format())
      : new Date(),
    animal: editReproduction ? editReproduction.animal : animal.id,
    status: editReproduction ? editReproduction.status : '',
    months: editReproduction ? editReproduction.months : null,
    notes: editReproduction ? editReproduction.notes : ''
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      if (values.months === '') values.months = null;
      if (!editReproduction) {
        const res = await createCheckupApi(values);
        setCheckups([res.data, ...checkups]);
        toast.success('Registro creado correctamente');
        formHook.resetValues();
      } else {
        const res = await updateCheckupApi(editReproduction.id, values);
        setCheckups(
          checkups.map((i) => {
            if (i.id === editReproduction.id) return res.data;
            return i;
          })
        );
        toast.success('Registro modificado correctamente');
      }
      setModalReproductionIsOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  const formHook = useForm({
    initialValues,
    validationSchema: checkupSchema,
    onSubmit: handleOnSubmit
  });

  const handleGoBack = () => {
    setTypeReproductiveCheck(null);
  };

  return (
    <>
      <FlexBox alignItems='center' marginBottom='2rem'>
        <Button className='go_back' onClick={handleGoBack}>
          <i className='mdi mdi-chevron-left' />
        </Button>
        <h5>{editReproduction ? 'Modificar' : 'Nuevo'} Chequeo</h5>
      </FlexBox>
      <form onSubmit={formHook.handleSubmit}>
        <FormGroup>
          <DatePicker
            label='Fecha'
            name='date'
            value={formHook.values.date}
            onChange={formHook.handleChange}
            error={formHook.errors.date}
          />
        </FormGroup>
        <Grid cols='2'>
          <FormGroup>
            <Select
              name='status'
              label='Estado'
              value={formHook.values.status}
              onChange={formHook.handleChange}
              error={formHook.errors.status}
              options={statusOptions}
            />
          </FormGroup>
          <FormGroup>
            <Field
              type='number'
              name='months'
              label='Meses de preñez'
              value={formHook.values.months ? formHook.values.months : ''}
              onChange={formHook.handleChange}
              error={formHook.errors.months}
            />
          </FormGroup>
        </Grid>
        <FormGroup>
          <Field
            type='textarea'
            label='Notas'
            name='notes'
            value={formHook.values.notes}
            onChange={formHook.handleChange}
            error={formHook.errors.notes}
          />
        </FormGroup>
        <FormGroup className='center'>
          <Button type='submit' className='success rounded' loading={loading}>
            {editReproduction ? 'Modificar' : 'Añadir'}
          </Button>
        </FormGroup>
        <FormGroup className='text_center' marginBottom='0'>
          <Button
            className='link'
            onClick={() => setModalReproductionIsOpen(false)}
          >
            Cerrar modal
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  checkups: state.reproduction.checkups,
  editReproduction: state.reproduction.editReproduction
});

const mapDispatchToProps = {
  setCheckups,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckupForm);
