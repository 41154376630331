import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  FlexBox,
  Card,
  CardBody,
  Table
} from '../components/ui';
import AnimalItem from '../components/animals/AnimalItem';

const Home = ({ animals }) => {
  const [endProductionList, setEndProductionList] = useState([]);
  const [weanList, setWeanList] = useState([]);
  const [inseminateList, setInseminateList] = useState([]);

  useEffect(() => {
    getEndProductionList();
    getWeanList();
    getInseminateList();
  }, [animals]);

  const getEndProductionList = () => {
    const results = animals.filter((animal) => animal.production_end === true);
    setEndProductionList(results);
  };

  const getWeanList = () => {
    const results = animals.filter((animal) => animal.wean === true);
    setWeanList(results);
  };

  const getInseminateList = () => {
    const results = animals.filter(
      (animal) => animal.should_inseminate === true
    );
    setInseminateList(results);
  };

  return (
    <>
      <Container>
        <Grid cols='2'>
          <div style={{ marginBottom: '3rem' }}>
            <Card className='sm light'>
              <CardBody>
                <h5 style={{ marginBottom: 0 }}>Datos Generales</h5>
              </CardBody>
            </Card>
            <Card className='sm'>
              <CardBody>
                <Table>
                  <tbody>
                    <tr>
                      <td>Total animales:</td>
                      <td>
                        {
                          animals.filter(
                            (animal) =>
                              animal.status !== 'death' &&
                              animal.status !== 'sold'
                          ).length
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Total hembras:</td>
                      <td>
                        {
                          animals.filter(
                            (animal) =>
                              animal.sex === 1 &&
                              animal.status !== 'death' &&
                              animal.status !== 'sold'
                          ).length
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Total machos:</td>
                      <td>
                        {
                          animals.filter(
                            (animal) =>
                              animal.sex === 2 &&
                              animal.status !== 'death' &&
                              animal.status !== 'sold'
                          ).length
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>En producción:</td>
                      <td>
                        {
                          animals.filter(
                            (animal) =>
                              animal.status === 'on_production' &&
                              animal.status !== 'death' &&
                              animal.status !== 'sold'
                          ).length
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Cargadas:</td>
                      <td>
                        {
                          animals.filter(
                            (animal) =>
                              animal.last_checkup_status === 'Cargada' &&
                              animal.status !== 'death' &&
                              animal.status !== 'sold'
                          ).length
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Vacas horras:</td>
                      <td>
                        {
                          animals.filter(
                            (animal) =>
                              animal.status === 'horra' &&
                              animal.status !== 'death' &&
                              animal.status !== 'sold'
                          ).length
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Terneras:</td>
                      <td>
                        {
                          animals.filter(
                            (animal) =>
                              animal.stage === 'Ternera' &&
                              animal.status !== 'death' &&
                              animal.status !== 'sold'
                          ).length
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Terneros:</td>
                      <td>
                        {
                          animals.filter(
                            (animal) =>
                              animal.stage === 'Ternero' &&
                              animal.status !== 'death' &&
                              animal.status !== 'sold'
                          ).length
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Novillas:</td>
                      <td>
                        {
                          animals.filter(
                            (animal) =>
                              animal.stage === 'Novilla' &&
                              animal.status !== 'death' &&
                              animal.status !== 'sold'
                          ).length
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Novillos:</td>
                      <td>
                        {
                          animals.filter(
                            (animal) =>
                              animal.stage === 'Novillo' &&
                              animal.status !== 'death' &&
                              animal.status !== 'sold'
                          ).length
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>En Venta:</td>
                      <td>
                        {
                          animals.filter(
                            (animal) =>
                              animal.on_sale &&
                              animal.status !== 'death' &&
                              animal.status !== 'sold'
                          ).length
                        }
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
          <div style={{ marginBottom: '3rem' }}>
            <Card className='sm light'>
              <CardBody>
                <FlexBox justifyContent='space-between' alignItems='center'>
                  <h5 style={{ marginBottom: 0 }}>Animales por destetar</h5>
                  <small>
                    Total de animales: <strong>{weanList.length}</strong>
                  </small>
                </FlexBox>
              </CardBody>
            </Card>
            <div style={{ marginBottom: '2rem' }}>
              {weanList.length > 0 ? (
                weanList.map((animal) => (
                  <AnimalItem key={animal.id} animal={animal} />
                ))
              ) : (
                <p>No hay animales por destetar.</p>
              )}
            </div>

            <Card className='sm light'>
              <CardBody>
                <FlexBox justifyContent='space-between' alignItems='center'>
                  <h5 style={{ marginBottom: 0 }}>Animales por secar</h5>
                  <small>
                    Total de animales:{' '}
                    <strong>{endProductionList.length}</strong>
                  </small>
                </FlexBox>
              </CardBody>
            </Card>
            <div style={{ marginBottom: '2rem' }}>
              {endProductionList.length > 0 ? (
                endProductionList.map((animal) => (
                  <AnimalItem key={animal.id} animal={animal} />
                ))
              ) : (
                <p>No hay animales por secar.</p>
              )}
            </div>

            <Card className='sm light'>
              <CardBody>
                <FlexBox justifyContent='space-between' alignItems='center'>
                  <h5 style={{ marginBottom: 0 }}>Animales por inseminar</h5>
                  <small>
                    Total de animales: <strong>{inseminateList.length}</strong>
                  </small>
                </FlexBox>
              </CardBody>
            </Card>
            <div style={{ marginBottom: '2rem' }}>
              {inseminateList.length > 0 ? (
                inseminateList.map((animal) => (
                  <AnimalItem key={animal.id} animal={animal} />
                ))
              ) : (
                <p>No hay animales por inseminar.</p>
              )}
            </div>
          </div>
        </Grid>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  animals: state.farms.animals
});

export default connect(mapStateToProps, null)(Home);
