import React from 'react';
import { connect } from 'react-redux';

import {
  Card,
  CardBody,
  Filters,
  Group,
  GridDefault,
  Title,
  Button,
  FormGroup,
  Input
} from '../ui';
import {
  setEditProduction,
  setTypeProduction,
  setModalProductionIsOpen
} from '../../redux/actions/production';
import ProductionModal from './ProductionModal';
import MilkList from './MilkList';
import WeightList from './WeightList';

const ProductionPanel = ({
  animals,
  animal,
  setEditProduction,
  setTypeProduction,
  setModalProductionIsOpen
}) => {
  const handleClickAdd = () => {
    setTypeProduction(null);
    setEditProduction(null);
    setModalProductionIsOpen(true);
  };

  return (
    <>
      <GridDefault>
        <Card>
          <CardBody>
            <Group marginBottom={2}>
              <Title Tag='h4' margin='md'>
                Producción
              </Title>
              <Button
                className='success fluid rounded'
                onClick={handleClickAdd}
              >
                + Agregar
              </Button>
            </Group>

            <div>
              <FormGroup>
                <Input type='text' placeholder='Buscar' />
              </FormGroup>
              <Filters>
                <h6 className='mb'>FILTRAR</h6>
                <ul>
                  <li>
                    <a>
                      <i className='mdi mdi-eye'></i> Todo
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className='mdi mdi-eye'></i> Leche
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className='mdi mdi-eye'></i> Peso
                    </a>
                  </li>
                </ul>
              </Filters>
            </div>
          </CardBody>
        </Card>

        {animal && (
          <div>
            <MilkList animal={animal} />
            <WeightList animal={animal} />
          </div>
        )}
      </GridDefault>
      <ProductionModal animals={animals} animal={animal} />
    </>
  );
};

const mapDispatchToProps = {
  setEditProduction,
  setTypeProduction,
  setModalProductionIsOpen
};

export default connect(null, mapDispatchToProps)(ProductionPanel);
