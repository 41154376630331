import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import MediaCard from './MediaCard';
import { getFilesApi, createFileApi } from '../api';
import { Modal, Grid, Button, Spinner, Field, InputFile } from './ui';

const MediaLibrary = ({ isOpen, setIsOpen, onFinish }) => {
  const [loading, setLoading] = useState(true);
  const [scene, setScene] = useState('browse');
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sendingFile, setSendingFile] = useState(false);

  useEffect(() => {
    getMediaLibrary();
  }, []);

  const getMediaLibrary = async () => {
    const res = await getFilesApi();
    if (res.status === 200) {
      setFiles(res.data.results);
    }
    setLoading(false);
  };

  const handleClickSelectFile = (file) => {
    setSelectedFile(file);
  };

  const handleClickFinish = () => {
    handleClose();
    onFinish(selectedFile);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAddAsset = () => {
    setScene('upload');
  };

  const handleChangeFile = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    setSendingFile(true);

    try {
      const res = await createFileApi(formData);
      const { data } = res;
      setFiles([data, ...files]);
      setSendingFile(false);
      toast.success('Archivo guardado correctamente.', 'success');
      setSelectedFile(data);
      setScene('browse');
    } catch (error) {
      if (error.response.data.detail) {
        toast.error(error.response.data.detail, 'error');
      }
      toast.error('Error en los datos enviados', 'error');
      console.log(error.response.data);
      setSendingFile(false);
    }
  };

  // const handleDeleteImage = async (e, id) => {
  //   e.preventDefault();

  //   try {
  //     const res = await axios.delete(`${config.api.product_images_url}${id}/`);
  //     if (res.status === 204) {
  //       MSG('Imagen eliminada correctamente.', 'success');
  //       setImages(images.filter((img) => img.id !== id));
  //     }
  //   } catch (error) {
  //     if (error.response.data.detail) {
  //       MSG(error.response.data.detail, 'error');
  //     }
  //     MSG('Error en los datos enviados', 'error');
  //     console.log(error.response.data);
  //   }
  // };

  return (
    <Modal
      title='Archivos multimedia'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      size='md'
      footer={
        <Footer>
          <Button className='cancel' onClick={handleClose}>
            Cancelar
          </Button>
          <Button className='success' onClick={handleClickFinish}>
            Finalizar
          </Button>
        </Footer>
      }
    >
      {scene === 'browse' ? (
        <>
          <Search>
            <Field type='text' name='search' placeholder='Buscar archivo...' />
            <Button className='primary' onClick={handleAddAsset}>
              Agregar archivos
            </Button>
          </Search>
          <FilesList>
            {loading ? (
              <Spinner />
            ) : files && files.length > 0 ? (
              <Grid cols={3}>
                {files.map((file) => (
                  <MediaCard
                    key={file.id}
                    file={file}
                    onClick={handleClickSelectFile}
                    selectedFile={selectedFile}
                  />
                ))}
              </Grid>
            ) : (
              <p className='not-files'>
                <strong>Todavía no hay archivos</strong>
                <br /> <small>Agrega uno a la lista.</small>
              </p>
            )}
          </FilesList>
        </>
      ) : sendingFile ? (
        <Spinner />
      ) : (
        <InputFile name='asset' onChange={handleChangeFile} />
      )}
    </Modal>
  );
};

const Search = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const FilesList = styled.div`
  min-height: 300px;
  margin-bottom: 1rem;

  .not-files {
    text-align: center;
    padding: 2rem 0;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: var(--border-color);
  flex-shrink: 0;
  padding: 1rem;
  border-top: 1px solid var(--border-color);
`;

export default MediaLibrary;
