import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  MenuDots,
  List,
  ListTitle,
  ListHead,
  ListRow,
  ListCol,
  DateFormat,
  Spinner
} from '../ui';
import {
  setCheckups,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck,
  setEditReproduction
} from '../../redux/actions/reproduction';
import { getCheckupsApi, deleteCheckupApi } from '../../api';

const CheckupList = ({
  animal,
  checkups,
  setCheckups,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck,
  setEditReproduction
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCheckups();
  }, [animal]);

  const getCheckups = async () => {
    try {
      const res = await getCheckupsApi(`?animal=${animal.id}`);
      setCheckups(res.data.results);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleClickEdit = (type, data) => {
    setModalReproductionIsOpen(true);
    setTypeReproductiveCheck(type);
    setEditReproduction(data);
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteCheckupApi(id);
      if (res.status == 204) {
        setCheckups(checkups.filter((i) => i.id !== id));
        toast.success('Registro eliminado correctamente');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <List>
      <ListTitle>
        <h5>Chequeos</h5>
      </ListTitle>
      <ListHead>
        <ListCol flex='15%'>Fecha</ListCol>
        <ListCol flex='20%'>Estado</ListCol>
        <ListCol flex='65%'>Meses</ListCol>
      </ListHead>
      {loading ? (
        <Spinner />
      ) : checkups && checkups.length > 0 ? (
        checkups.map((checkup) => (
          <ListRow key={checkup.id}>
            <ListCol flex='15%'>
              <DateFormat date={checkup.date} />
            </ListCol>
            <ListCol flex='20%'>{checkup.status_display}</ListCol>
            <ListCol flex='60%'>
              {checkup.months}&nbsp;
              <small>
                {checkup.days_remaining_text &&
                  `(${checkup.days_remaining_text})`}
              </small>
            </ListCol>
            <ListCol>
              <MenuDots>
                <span onClick={() => handleClickEdit('checkup', checkup)}>
                  Editar
                </span>
                <span onClick={() => handleDelete(checkup.id)}>Eliminar</span>
              </MenuDots>
            </ListCol>
          </ListRow>
        ))
      ) : (
        <ListRow className='no-results'>No hay chequeos registrados.</ListRow>
      )}
    </List>
  );
};

const mapStateToProps = (state) => ({
  checkups: state.reproduction.checkups
});

const mapDispatchToProps = {
  setCheckups,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck,
  setEditReproduction
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckupList);
