import React from 'react';
import styled from 'styled-components';

import Button from './Button';

export const Card = styled.div`
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);

  &.shadow {
    &:hover {
      box-shadow: 0 4px 10px 0 rgba(93, 130, 170, 0.21);
    }
  }

  &.light {
    border-color: white;
  }

  &.pointer {
    cursor: pointer;
  }

  &.sm {
    margin-bottom: 1rem;
  }

  &.md {
    margin-bottom: 2rem;
  }

  &.lg {
    margin-bottom: 3rem;
  }
`;

export const CardHeader = ({ children, history }) => {
  const handleOnClick = () => {
    if (history) history.goBack();
  };

  return (
    <CardHeaderWrapper>
      {history && (
        <Button className='go_back' onClick={handleOnClick}>
          <i className='mdi mdi-chevron-left' />
        </Button>
      )}
      <div className='children'>{children}</div>
    </CardHeaderWrapper>
  );
};

const CardHeaderWrapper = styled.div`
  padding: 1rem 1.6rem;
  background-color: var(--color-gray-light);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .children {
    flex: 1;
  }

  h2 {
    font-size: 1.3rem;
  }
`;

export const CardBody = styled.div`
  padding: 1rem 1.6rem;

  &.sm {
    padding: 0.5rem 1rem;
  }

  &.md {
    padding: 1.6rem 1.6rem;
  }

  &.lg {
    padding: 2rem;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
  border-top: 1px solid var(--border-color);

  button,
  button:hover {
    color: var(--color-gray);
    font-weight: 500;
    font-size: 1rem;
    padding: 0 1.5em;
    height: 54px;
    line-height: 54px;
    border-radius: 0;
    border: 0;
    background: transparent;
    border-bottom: 3px solid transparent;
    transition: color 0.1s ease;

    &.active {
      color: var(--color-dark);
      border-color: var(--color-primary) !important;
    }
  }
`;
