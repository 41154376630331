import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import useDateString from '../../hooks/useDateString';

const DateFormat = ({
  date,
  ageString,
  remainingDays,
  laterDays,
  nextDate
}) => {
  const parseDate = moment(date).startOf('day');
  const today = moment().startOf('day');

  const calendarDays = today.diff(parseDate, 'days', true);
  const { dateString: ageStringDate } = useDateString(calendarDays);

  const parseNextDate = parseDate.add(remainingDays, 'days');
  const { daysString: remainingDaysString } = useDateString(
    parseNextDate.diff(today, 'days', true)
  );
  const { daysString: laterDaysString } = useDateString(
    today.diff(parseDate, 'days', true)
  );

  let dateFormatter = parseDate.format('DD-MMM-YYYY');
  if (ageString) dateFormatter = ageStringDate;
  if (remainingDays) dateFormatter = remainingDaysString;
  if (laterDays) dateFormatter = laterDaysString;
  if (nextDate) dateFormatter = parseNextDate.format('DD-MMM-YYYY');

  return <time dateTime={parseDate}>{dateFormatter}</time>;
};

export default DateFormat;
