import React from 'react';
import styled from 'styled-components';

export const List = styled.div`
  margin-bottom: 2rem;
`;

export const ListTitle = styled.div`
  padding: 0.5rem 0rem;
`;

export const ListHead = styled.div`
  display: flex;
  padding: 1rem 0.5rem 1rem 1.5rem;
  align-items: center;

  div {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    color: var(--color-gray);
  }
`;

export const ListRow = styled.div`
  display: flex;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  padding: 1rem 0.5rem 1rem 1.5rem;
  margin-bottom: 0.5rem;
  background-color: white;
  align-items: center;
  transition: box-shadow 0.2s;

  &.no-results {
    background-color: transparent;
    border: none;
    color: var(--color-gray);
    font-size: 0.9rem;
  }
`;

export const ListCol = styled.div`
  display: flex;
  align-items: center;
  ${({ flex }) => flex && `flex: 0 0 ${flex};`};
`;

export const ItemData = styled.p`
  span {
    display: block;
    font-size: 0.7rem;
    color: var(--color-gray);
    margin-bottom: 0.2rem;
  }
`;
