export const healthActionTypes = {
  SET_TREATMENTS: 'SET_TREATMENTS',
  SET_VACCINES: 'SET_VACCINES',
  SET_DEATH: 'SET_DEATH',
  SET_EDIT_HEALTH: 'SET_EDIT_HEALTH',
  SET_TYPE_HEALTH: 'SET_TYPE_HEALTH',
  SET_MODAL_HEALTH_IS_OPEN: 'SET_MODAL_HEALTH_IS_OPEN'
};

export const setTreatments = (treatments) => {
  return {
    type: healthActionTypes.SET_TREATMENTS,
    payload: { treatments }
  };
};

export const setVaccines = (vaccines) => {
  return {
    type: healthActionTypes.SET_VACCINES,
    payload: { vaccines }
  };
};

export const setDeath = (death) => {
  return {
    type: healthActionTypes.SET_DEATH,
    payload: { death }
  };
};

export const setEditHealth = (editHealth) => {
  return {
    type: healthActionTypes.SET_EDIT_HEALTH,
    payload: { editHealth }
  };
};

export const setTypeHealth = (typeHealth) => {
  return {
    type: healthActionTypes.SET_TYPE_HEALTH,
    payload: { typeHealth }
  };
};

export const setModalHealthIsOpen = (modalHealthIsOpen) => {
  return {
    type: healthActionTypes.SET_MODAL_HEALTH_IS_OPEN,
    payload: { modalHealthIsOpen }
  };
};
