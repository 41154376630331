import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  MenuDots,
  List,
  ListTitle,
  ListHead,
  ListRow,
  ListCol,
  DateFormat,
  Spinner
} from '../ui';
import {
  setVaccines,
  setModalHealthIsOpen,
  setTypeHealth,
  setEditHealth
} from '../../redux/actions/health';
import { getVaccinesApi, deleteVaccineApi } from '../../api';

const VaccineList = ({
  animal,
  vaccines,
  setVaccines,
  setModalHealthIsOpen,
  setTypeHealth,
  setEditHealth
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getVaccines();
  }, [animal]);

  const getVaccines = async () => {
    try {
      const res = await getVaccinesApi(`?animal=${animal.id}`);
      setVaccines(res.data.results);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleClickEdit = (type, data) => {
    setModalHealthIsOpen(true);
    setTypeHealth(type);
    setEditHealth(data);
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteVaccineApi(id);
      if (res.status == 204) {
        setVaccines(vaccines.filter((i) => i.id !== id));
        toast.success('Registro eliminado correctamente');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <List>
      <ListTitle>
        <h5>Vacunas</h5>
      </ListTitle>
      <ListHead>
        <ListCol flex='15%'>Fecha</ListCol>
        <ListCol flex='40%'>Vacuna</ListCol>
        <ListCol flex='40%'>Enfermedad a tratar</ListCol>
      </ListHead>
      {loading ? (
        <Spinner />
      ) : vaccines && vaccines.length > 0 ? (
        vaccines.map((vaccine) => (
          <ListRow key={vaccine.id}>
            <ListCol flex='15%'>
              <DateFormat date={vaccine.date} />
            </ListCol>
            <ListCol flex='40%'>{vaccine.vaccine}</ListCol>
            <ListCol flex='40%'>{vaccine.disease}</ListCol>
            <ListCol>
              <MenuDots>
                <span onClick={() => handleClickEdit('vaccine', vaccine)}>
                  Editar
                </span>
                <span onClick={() => handleDelete(vaccine.id)}>Eliminar</span>
              </MenuDots>
            </ListCol>
          </ListRow>
        ))
      ) : (
        <ListRow className='no-results'>No hay vacunación registrada.</ListRow>
      )}
    </List>
  );
};

const mapStateToProps = (state) => ({
  vaccines: state.health.vaccines
});

const mapDispatchToProps = {
  setVaccines,
  setModalHealthIsOpen,
  setTypeHealth,
  setEditHealth
};

export default connect(mapStateToProps, mapDispatchToProps)(VaccineList);
