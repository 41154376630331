import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import moment from 'moment';

import { setAnimals } from '../../redux/actions/farms';
import { createAnimalApi, updateAnimalApi } from '../../api';
import { Grid, FormGroup, Field, Select, DatePicker, Button } from '../ui';
import MediaLibrary from '../MediaLibrary';
import useForm from '../../hooks/useForm';

const animalSchema = Yup.object({
  name: Yup.string().max(200).required('El nombre es requerido'),
  sex: Yup.number()
    .typeError('El sexo es requerido')
    .required('El sexo es requerido'),
  code: Yup.string().required('Código requerido'),
  photo: Yup.number().nullable(),
  breed: Yup.number().typeError('Raza requerida').required(),
  birth: Yup.date().typeError('Fecha de nacimiento es requerida'),
  status: Yup.string().required('Estado requerido'),
  purpose: Yup.number().typeError('Propósito requerido').required(),
  mother: Yup.number().nullable(),
  father: Yup.number().nullable()
});

const sexOptions = [
  { value: 1, label: 'Hembra' },
  { value: 2, label: 'Macho' }
];

const statusOptions = [
  { value: 'on_the_farm', label: 'En la finca' },
  { value: 'on_production', label: 'En Producción' },
  { value: 'horra', label: 'Vaca Horra' },
  { value: 'on_sale', label: 'En Venta' },
  { value: 'sold', label: 'Vendido' },
  { value: 'death', label: 'Fallecido' }
];

const purposeOptions = [
  { value: 1, label: 'Leche' },
  { value: 2, label: 'Carne' },
  { value: 3, label: 'Doble Propósito' }
];

const AnimalForm = ({
  animal,
  breeds,
  setModalIsOpen,
  animals,
  setAnimals
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [mediaLibraryIsOpen, setMediaLibraryIsOpen] = useState(false);

  const motherOptions = animals
    .filter((animal) => animal.sex === 1)
    .map((animal) => ({
      value: animal.id,
      label: `${animal.code} ${animal.name}`
    }));

  const fatherOptions = animals
    .filter((animal) => animal.sex === 2)
    .map((animal) => ({
      value: animal.id,
      label: `${animal.code} ${animal.name}`
    }));

  let initialValues = {
    name: animal ? animal.name : '',
    sex: animal ? animal.sex : '',
    code: animal ? animal.code : '',
    breed: animal ? animal.breed : '',
    birth: animal ? new Date(moment(animal.birth).format()) : '',
    photo: animal ? animal.photo : null,
    purpose: animal ? animal.purpose : '',
    status: animal ? animal.status : 'on_the_farm',
    mother: animal ? animal.mother : null,
    father: animal ? animal.father : null
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      if (!animal) {
        const res = await createAnimalApi(values);
        setAnimals([res.data, ...animals]);
        toast.success('Animal creado correctamente');
        formHook.resetValues();
      } else {
        const res = await updateAnimalApi(animal.uuid, values);
        const animalsUpdated = animals.map((a) =>
          a.uuid === animal.uuid ? res.data : a
        );
        setAnimals(animalsUpdated);
        toast.success('Animal modificado correctamente');
      }
      setModalIsOpen(false);
    } catch (error) {
      toast.error(JSON.stringify(error.response));
      setLoading(false);
    }
  };

  const formHook = useForm({
    initialValues,
    validationSchema: animalSchema,
    onSubmit: handleOnSubmit
  });

  const handleOpenMediaLibrary = () => {
    setMediaLibraryIsOpen(true);
  };

  const onFinishMediaLibrary = (selectedFile) => {
    setSelectedPhoto(selectedFile);
    formHook.handleChange({
      target: { name: 'photo', value: selectedFile.id }
    });
  };

  return (
    <>
      <form onSubmit={formHook.handleSubmit}>
        <FormGroup>
          {selectedPhoto ? (
            <img src={selectedPhoto.file} width='140' />
          ) : (
            animal &&
            animal.photo && <img src={animal.photo_display.file} width='140' />
          )}
          <Button className='success' onClick={handleOpenMediaLibrary}>
            Agregar Foto
          </Button>
        </FormGroup>
        <FormGroup>
          <DatePicker
            label='Fecha de nacimiento'
            name='birth'
            value={formHook.values.birth}
            onChange={formHook.handleChange}
            error={formHook.errors.birth}
          />
        </FormGroup>
        <FormGroup>
          <Field
            type='text'
            label='Nombre'
            name='name'
            value={formHook.values.name}
            onChange={formHook.handleChange}
            error={formHook.errors.name}
          />
        </FormGroup>
        <Grid cols={2}>
          <FormGroup>
            <Select
              name='sex'
              label='Sexo'
              value={formHook.values.sex}
              onChange={formHook.handleChange}
              error={formHook.errors.sex}
              options={sexOptions}
            />
          </FormGroup>
          <FormGroup>
            <Field
              type='text'
              label='Número del animal'
              name='code'
              value={formHook.values.code}
              onChange={formHook.handleChange}
              error={formHook.errors.code}
            />
          </FormGroup>
        </Grid>
        <Grid cols={2}>
          <FormGroup>
            <Select
              name='breed'
              label='Raza'
              value={formHook.values.breed}
              onChange={formHook.handleChange}
              error={formHook.errors.breed}
              options={breeds}
            />
          </FormGroup>
          <FormGroup>
            <Select
              name='purpose'
              label='Propósito'
              value={formHook.values.purpose}
              onChange={formHook.handleChange}
              error={formHook.errors.purpose}
              options={purposeOptions}
            />
          </FormGroup>
        </Grid>
        <FormGroup>
          <Select
            name='status'
            label='Estado'
            value={formHook.values.status}
            onChange={formHook.handleChange}
            error={formHook.errors.status}
            options={statusOptions}
          />
        </FormGroup>
        <FormGroup>
          <Select
            name='father'
            label='Padre'
            value={formHook.values.father}
            onChange={formHook.handleChange}
            error={formHook.errors.father}
            options={fatherOptions}
          />
        </FormGroup>
        <FormGroup>
          <Select
            name='mother'
            label='Madre'
            value={formHook.values.mother}
            onChange={formHook.handleChange}
            error={formHook.errors.mother}
            options={motherOptions}
          />
        </FormGroup>
        <FormGroup className='center'>
          <Button type='submit' className='success rounded' loading={loading}>
            {animal ? 'Modificar animal' : 'Añadir animal'}
          </Button>
        </FormGroup>
        <FormGroup className='text_center' marginBottom='0'>
          <Button className='link' onClick={() => setModalIsOpen(false)}>
            Cerrar modal
          </Button>
        </FormGroup>
      </form>

      <MediaLibrary
        isOpen={mediaLibraryIsOpen}
        setIsOpen={setMediaLibraryIsOpen}
        onFinish={onFinishMediaLibrary}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.farms.user,
  farm: state.farms.farm,
  animals: state.farms.animals,
  breeds: state.farms.breeds
});

const mapDispatchToProps = {
  setAnimals
};

export default connect(mapStateToProps, mapDispatchToProps)(AnimalForm);
