import { Axios, ApiUrl } from './config';

// Treatments
export const getTreatmentsApi = async (query) => {
  const res = await Axios.get(
    `${ApiUrl}/health/treatments/${query ? `${query}` : ''}`
  );
  return res;
};

export const getTreatmentApi = async (id) => {
  const res = await Axios.get(`${ApiUrl}/health/treatments/${id}/`);
  return res;
};

export const createTreatmentApi = async (data) => {
  const res = await Axios.post(`${ApiUrl}/health/treatments/`, data);
  return res;
};

export const updateTreatmentApi = async (id, data) => {
  const res = await Axios.put(`${ApiUrl}/health/treatments/${id}/`, data);
  return res;
};

export const deleteTreatmentApi = async (id) => {
  const res = await Axios.delete(`${ApiUrl}/health/treatments/${id}/`);
  return res;
};

// Vaccines
export const getVaccinesApi = async (query) => {
  const res = await Axios.get(
    `${ApiUrl}/health/vaccines/${query ? `${query}` : ''}`
  );
  return res;
};

export const getVaccineApi = async (id) => {
  const res = await Axios.get(`${ApiUrl}/health/vaccines/${id}/`);
  return res;
};

export const createVaccineApi = async (data) => {
  const res = await Axios.post(`${ApiUrl}/health/vaccines/`, data);
  return res;
};

export const updateVaccineApi = async (id, data) => {
  const res = await Axios.put(`${ApiUrl}/health/vaccines/${id}/`, data);
  return res;
};

export const deleteVaccineApi = async (id) => {
  const res = await Axios.delete(`${ApiUrl}/health/vaccines/${id}/`);
  return res;
};

// Deaths
export const getDeathsApi = async (query) => {
  const res = await Axios.get(
    `${ApiUrl}/health/deaths/${query ? `${query}` : ''}`
  );
  return res;
};

export const getDeathApi = async (id) => {
  const res = await Axios.get(`${ApiUrl}/health/deaths/${id}/`);
  return res;
};

export const createDeathApi = async (data) => {
  const res = await Axios.post(`${ApiUrl}/health/deaths/`, data);
  return res;
};

export const updateDeathApi = async (id, data) => {
  const res = await Axios.put(`${ApiUrl}/health/deaths/${id}/`, data);
  return res;
};

export const deleteDeathApi = async (id) => {
  const res = await Axios.delete(`${ApiUrl}/health/deaths/${id}/`);
  return res;
};
