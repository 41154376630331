import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  setTypeReproductiveCheck,
  setEditReproduction,
  setModalReproductionIsOpen
} from '../../redux/actions/reproduction';
import { Grid, Modal, Card } from '../ui';
import InseminationForm from './InseminationForm';
import CheckupForm from './CheckupForm';
import BirthForm from './BirthForm';
import AbortionForm from './AbortionForm';

const ReproductionModal = ({
  animals,
  animal,
  typeReproductiveCheck,
  setTypeReproductiveCheck,
  modalReproductionIsOpen,
  setModalReproductionIsOpen,
  setEditReproduction
}) => {
  const handleClickReproductiveCheck = (item) => {
    setTypeReproductiveCheck(item);
  };

  const onCloseModalChange = () => {
    setTypeReproductiveCheck(null);
    setEditReproduction(null);
  };

  return (
    <Modal
      title='Chequeo reproductivo'
      size='md'
      isOpen={modalReproductionIsOpen}
      setIsOpen={setModalReproductionIsOpen}
      onClose={onCloseModalChange}
    >
      {!typeReproductiveCheck ? (
        <GridItems
          handleClickReproductiveCheck={handleClickReproductiveCheck}
        />
      ) : (
        <>
          {typeReproductiveCheck === 'insemination' && (
            <InseminationForm animal={animal} animals={animals} />
          )}
          {typeReproductiveCheck === 'checkup' && (
            <CheckupForm animal={animal} animals={animals} />
          )}
          {typeReproductiveCheck === 'birth' && (
            <BirthForm animal={animal} animals={animals} />
          )}
          {typeReproductiveCheck === 'abortion' && (
            <AbortionForm animal={animal} animals={animals} />
          )}
        </>
      )}
    </Modal>
  );
};

const GridItems = ({ handleClickReproductiveCheck }) => {
  return (
    <Grid cols='4'>
      <Card>
        <ItemLink
          color='#ffc107'
          onClick={() => handleClickReproductiveCheck('insemination')}
        >
          <i className='mdi mdi-cow'></i>
          <h5>Inseminación</h5>
        </ItemLink>
      </Card>
      <Card>
        <ItemLink
          color='#478bee'
          onClick={() => handleClickReproductiveCheck('checkup')}
        >
          <i className='mdi mdi-cow'></i>
          <h5>Chequeo</h5>
        </ItemLink>
      </Card>
      <Card>
        <ItemLink
          color='#00aa70'
          onClick={() => handleClickReproductiveCheck('birth')}
        >
          <i className='mdi mdi-cow'></i>
          <h5>Parto</h5>
        </ItemLink>
      </Card>
      <Card>
        <ItemLink
          color='#dc3545'
          onClick={() => handleClickReproductiveCheck('abortion')}
        >
          <i className='mdi mdi-cow'></i>
          <h5>Aborto</h5>
        </ItemLink>
      </Card>
    </Grid>
  );
};

const ItemLink = styled.div`
  text-align: center;
  cursor: pointer;
  padding: 1rem 1.6rem;
  border-radius: var(--border-radius);

  &:hover {
    background-color: var(--color-light);
  }

  i {
    font-size: 5rem;
    display: block;
    margin-bottom: 1rem;
    ${({ color }) => color && `color: ${color};`}
  }
`;

const mapStateToProps = (state) => ({
  typeReproductiveCheck: state.reproduction.typeReproductiveCheck,
  modalReproductionIsOpen: state.reproduction.modalReproductionIsOpen
});

const mapDispatchToProps = {
  setTypeReproductiveCheck,
  setEditReproduction,
  setModalReproductionIsOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(ReproductionModal);
