import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';

import {
  Grid,
  FormGroup,
  Select,
  DatePicker,
  Field,
  FlexBox,
  Button
} from '../ui';
import {
  setInseminations,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck
} from '../../redux/actions/reproduction';
import { createInseminationApi, updateInseminationApi } from '../../api';
import useForm from '../../hooks/useForm';

const inseminationSchema = Yup.object().shape({
  date: Yup.date()
    .typeError('Ingresa una fecha válida.')
    .required('Fecha requerida.'),
  animal: Yup.number().required(),
  type_of: Yup.string().required('Tipo de fecundación requerido'),
  straw_number: Yup.string().max(100),
  notes: Yup.string(),
  bull: Yup.number().nullable()
});

const typeOptions = [
  { value: 1, label: 'Monta' },
  { value: 2, label: 'Inseminación' },
  { value: 3, label: 'Transferencia' },
  { value: 4, label: 'Otros' }
];

const InseminationForm = ({
  animal,
  animals,
  inseminations,
  setInseminations,
  editReproduction,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck
}) => {
  const [loading, setLoading] = useState(false);

  let initialValues = {
    date: editReproduction
      ? new Date(moment(editReproduction.date).format())
      : new Date(),
    animal: editReproduction ? editReproduction.animal : animal.id,
    type_of: editReproduction ? editReproduction.type_of : '',
    straw_number: editReproduction ? editReproduction.straw_number : '',
    notes: editReproduction ? editReproduction.notes : '',
    bull: editReproduction ? editReproduction.bull : null
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      if (!editReproduction) {
        const res = await createInseminationApi(values);
        setInseminations([res.data, ...inseminations]);
        toast.success('Registro creado correctamente');
        formHook.resetValues();
      } else {
        const res = await updateInseminationApi(editReproduction.id, values);
        setInseminations(
          inseminations.map((i) => {
            if (i.id === editReproduction.id) return res.data;
            return i;
          })
        );
        toast.success('Registro modificado correctamente');
      }
      setModalReproductionIsOpen(false);
    } catch (error) {
      console.log(error.response);
      toast.error(error);
      setLoading(false);
    }
  };

  const formHook = useForm({
    initialValues,
    validationSchema: inseminationSchema,
    onSubmit: handleOnSubmit
  });

  const handleGoBack = () => {
    setTypeReproductiveCheck(null);
  };

  return (
    <>
      <FlexBox alignItems='center' marginBottom='2rem'>
        <Button className='go_back' onClick={handleGoBack}>
          <i className='mdi mdi-chevron-left' />
        </Button>
        <h5>{editReproduction ? 'Modificar' : 'Nueva'} Inseminación</h5>
      </FlexBox>
      <form onSubmit={formHook.handleSubmit}>
        <FormGroup>
          <DatePicker
            label='Fecha'
            name='date'
            value={formHook.values.date}
            onChange={formHook.handleChange}
            error={formHook.errors.date}
          />
        </FormGroup>
        <Grid cols={2}>
          <FormGroup>
            <Select
              name='type_of'
              label='Tipo de fecundación'
              value={formHook.values.type_of}
              onChange={formHook.handleChange}
              error={formHook.errors.type_of}
              options={typeOptions}
            />
          </FormGroup>
          <FormGroup>
            <Field
              type='text'
              label='Nº pajilla'
              name='straw_number'
              value={formHook.values.straw_number}
              onChange={formHook.handleChange}
              error={formHook.errors.straw_number}
            />
          </FormGroup>
        </Grid>
        <FormGroup>
          <Select
            name='bull'
            label='Toro'
            value={formHook.values.bull}
            onChange={formHook.handleChange}
            error={formHook.errors.bull}
            options={animals
              .filter((a) => a.sex === 2)
              .map((a) => ({ value: a.id, label: a.name }))}
          />
        </FormGroup>
        <FormGroup>
          <Field
            type='textarea'
            label='Notas'
            name='notes'
            value={formHook.values.notes}
            onChange={formHook.handleChange}
            error={formHook.errors.notes}
          />
        </FormGroup>
        <FormGroup className='center'>
          <Button type='submit' className='success rounded' loading={loading}>
            {editReproduction ? 'Modificar' : 'Añadir'}
          </Button>
        </FormGroup>
        <FormGroup className='text_center' marginBottom='0'>
          <Button
            className='link'
            onClick={() => setModalReproductionIsOpen(false)}
          >
            Cerrar modal
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  inseminations: state.reproduction.inseminations,
  editReproduction: state.reproduction.editReproduction
});

const mapDispatchToProps = {
  setInseminations,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck
};

export default connect(mapStateToProps, mapDispatchToProps)(InseminationForm);
