import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  MenuDots,
  List,
  ListTitle,
  ListHead,
  ListRow,
  ListCol,
  DateFormat,
  Spinner
} from '../ui';
import {
  setAbortions,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck,
  setEditReproduction
} from '../../redux/actions/reproduction';
import { getAbortionsApi, deleteAbortionApi } from '../../api';

const AbortionList = ({
  animal,
  abortions,
  setAbortions,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck,
  setEditReproduction
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAbortions();
  }, [animal]);

  const getAbortions = async () => {
    try {
      const res = await getAbortionsApi(`?animal=${animal.id}`);
      setAbortions(res.data.results);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleClickEdit = (type, data) => {
    setModalReproductionIsOpen(true);
    setTypeReproductiveCheck(type);
    setEditReproduction(data);
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteAbortionApi(id);
      if (res.status == 204) {
        setAbortions(abortions.filter((i) => i.id !== id));
        toast.success('Registro eliminado correctamente');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <List>
      <ListTitle>
        <h5>Abortos</h5>
      </ListTitle>
      <ListHead>
        <ListCol flex='40%'>Fecha</ListCol>
        <ListCol flex='55%'>Sexo</ListCol>
      </ListHead>
      {loading ? (
        <Spinner />
      ) : abortions && abortions.length > 0 ? (
        abortions.map((abortion) => (
          <ListRow key={abortion.id}>
            <ListCol flex='40%'>
              <DateFormat date={abortion.date} />
            </ListCol>
            <ListCol flex='55%'>{abortion.sex_display}</ListCol>
            <ListCol>
              <MenuDots>
                <span onClick={() => handleClickEdit('abortion', abortion)}>
                  Editar
                </span>
                <span onClick={() => handleDelete(abortion.id)}>Eliminar</span>
              </MenuDots>
            </ListCol>
          </ListRow>
        ))
      ) : (
        <ListRow className='no-results'>No hay abortos registrados.</ListRow>
      )}
    </List>
  );
};

const mapStateToProps = (state) => ({
  abortions: state.reproduction.abortions
});

const mapDispatchToProps = {
  setAbortions,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck,
  setEditReproduction
};

export default connect(mapStateToProps, mapDispatchToProps)(AbortionList);
