import { eventsActionTypes } from '../actions/events';

const initialState = {
  events: [],
  editEvent: null,
  modalEventIsOpen: false
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case eventsActionTypes.SET_EVENTS:
      return { ...state, ...action.payload };
    case eventsActionTypes.SET_EDIT_EVENT:
      return { ...state, ...action.payload };
    case eventsActionTypes.SET_MODAL_EVENT_IS_OPEN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default eventsReducer;
