import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';

import { FormGroup, DatePicker, Field, FlexBox, Button } from '../ui';
import {
  setTreatments,
  setTypeHealth,
  setModalHealthIsOpen
} from '../../redux/actions/health';
import { createTreatmentApi, updateTreatmentApi } from '../../api';
import useForm from '../../hooks/useForm';

const treatmentSchema = Yup.object().shape({
  date: Yup.date()
    .typeError('Ingresa una fecha válida')
    .required('Fecha requerida'),
  animal: Yup.number().required(),
  diagnosis: Yup.string().max(255).required('Diagnóstico requerido'),
  medicine: Yup.string().max(255).required('Medicamento requerido'),
  days: Yup.number()
    .typeError('Ingresa los días o un cero')
    .integer()
    .nullable(true),
  notes: Yup.string()
});

const TreatmentForm = ({
  animal,
  treatments,
  setTreatments,
  editHealth,
  setModalHealthIsOpen,
  setTypeHealth
}) => {
  const [loading, setLoading] = useState(false);
  let initialValues = {
    date: editHealth ? new Date(moment(editHealth.date).format()) : new Date(),
    animal: editHealth ? editHealth.animal : animal.id,
    diagnosis: editHealth ? editHealth.diagnosis : '',
    medicine: editHealth ? editHealth.medicine : '',
    days: editHealth ? editHealth.days : 0,
    notes: editHealth ? editHealth.notes : ''
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      if (!editHealth) {
        const res = await createTreatmentApi(values);
        setTreatments([res.data, ...treatments]);
        toast.success('Registro creado correctamente');
        formHook.resetValues();
      } else {
        const res = await updateTreatmentApi(editHealth.id, values);
        setTreatments(
          treatments.map((i) => {
            if (i.id === editHealth.id) return res.data;
            return i;
          })
        );
        toast.success('Registro modificado correctamente');
      }
      setModalHealthIsOpen(false);
    } catch (error) {
      console.log(error.response);
      toast.error(error);
      setLoading(false);
    }
  };

  const formHook = useForm({
    initialValues,
    validationSchema: treatmentSchema,
    onSubmit: handleOnSubmit
  });

  const handleGoBack = () => {
    setTypeHealth(null);
  };

  return (
    <>
      <FlexBox alignItems='center' marginBottom='2rem'>
        <Button className='go_back' onClick={handleGoBack}>
          <i className='mdi mdi-chevron-left' />
        </Button>
        <h5>{editHealth ? 'Modificar' : 'Nuevo'} Tratamiento</h5>
      </FlexBox>
      <form onSubmit={formHook.handleSubmit}>
        <FormGroup>
          <DatePicker
            label='Fecha'
            name='date'
            value={formHook.values.date}
            onChange={formHook.handleChange}
            error={formHook.errors.date}
          />
        </FormGroup>
        <FormGroup>
          <Field
            type='text'
            label='Diagnóstico'
            name='diagnosis'
            value={formHook.values.diagnosis}
            onChange={formHook.handleChange}
            error={formHook.errors.diagnosis}
          />
        </FormGroup>
        <FormGroup>
          <Field
            type='text'
            label='Medicamento'
            name='medicine'
            value={formHook.values.medicine}
            onChange={formHook.handleChange}
            error={formHook.errors.medicine}
          />
        </FormGroup>
        <FormGroup>
          <Field
            type='number'
            label='Días tratamiento'
            name='days'
            value={formHook.values.days}
            onChange={formHook.handleChange}
            error={formHook.errors.days}
          />
        </FormGroup>
        <FormGroup>
          <Field
            type='textarea'
            label='Notas'
            name='notes'
            value={formHook.values.notes}
            onChange={formHook.handleChange}
            error={formHook.errors.notes}
          />
        </FormGroup>
        <FormGroup className='center'>
          <Button type='submit' className='success rounded' loading={loading}>
            {editHealth ? 'Modificar' : 'Añadir'}
          </Button>
        </FormGroup>
        <FormGroup className='text_center' marginBottom='0'>
          <Button className='link' onClick={() => setModalHealthIsOpen(false)}>
            Cerrar modal
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  treatments: state.health.treatments,
  editHealth: state.health.editHealth
});

const mapDispatchToProps = {
  setTreatments,
  setTypeHealth,
  setModalHealthIsOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentForm);
