import { useState, useEffect } from 'react';

const useDateString = (calendarDays) => {
  const [dateString, setDateString] = useState(null);
  const [daysString, setDaysString] = useState(null);

  useEffect(() => {
    calculateDateString();
  }, []);

  const pluralize = (value, srt) => {
    if (value === 1) return '';
    return srt || 's';
  };

  const calculateDateString = () => {
    const hoursDay = 24;
    const hoursMonth = (365 * 24) / 12;
    const hoursYear = 24 * 365;
    let hours = calendarDays * 24;
    const res = [];

    const years = Math.floor(hours / hoursYear);
    const hoursOnYears = years * hoursYear;
    hours = hours - hoursOnYears;

    if (years > 0) res.push(`${years} año${pluralize(years)}`);

    const months = Math.floor(hours / hoursMonth);
    const hoursOnMonths = months * hoursMonth;
    hours = hours - hoursOnMonths;
    if (months > 0) res.push(`${months} mes${pluralize(months, 'es')}`);

    const days = Math.floor(hours / hoursDay);
    if (days > 0) res.push(`${days} día${pluralize(days)}`);

    setDateString(res.join(', '));

    setDaysString(`${calendarDays} día${pluralize(calendarDays)}`);
  };

  return {
    dateString,
    daysString
  };
};

export default useDateString;
