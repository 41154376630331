import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';

import {
  Grid,
  FormGroup,
  Select,
  DatePicker,
  Field,
  FlexBox,
  Button
} from '../ui';
import {
  setAbortions,
  setTypeReproductiveCheck,
  setModalReproductionIsOpen
} from '../../redux/actions/reproduction';
import { createAbortionApi, updateAbortionApi } from '../../api';
import useForm from '../../hooks/useForm';

const abortionSchema = Yup.object().shape({
  date: Yup.date()
    .typeError('Ingresa una fecha válida.')
    .required('Fecha requerida.'),
  animal: Yup.number().required(),
  sex: Yup.string().required('Sexo requerido.'),
  notes: Yup.string()
});

const sexOptions = [
  { value: 1, label: 'Hembra' },
  { value: 2, label: 'Macho' }
];

const AbortionForm = ({
  animal,
  abortions,
  setAbortions,
  editReproduction,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck
}) => {
  const [loading, setLoading] = useState(false);
  let initialValues = {
    date: editReproduction
      ? new Date(moment(editReproduction.date).format())
      : new Date(),
    animal: editReproduction ? editReproduction.animal : animal.id,
    sex: editReproduction ? editReproduction.sex : '',
    notes: editReproduction ? editReproduction.notes : ''
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      if (!editReproduction) {
        const res = await createAbortionApi(values);
        setAbortions([res.data, ...abortions]);
        toast.success('Registro creado correctamente');
        formHook.resetValues();
      } else {
        const res = await updateAbortionApi(editReproduction.id, values);
        setAbortions(
          abortions.map((i) => {
            if (i.id === editReproduction.id) return res.data;
            return i;
          })
        );
        toast.success('Registro modificado correctamente');
      }
      setModalReproductionIsOpen(false);
    } catch (error) {
      console.log(error.response);
      toast.error(error);
      setLoading(false);
    }
  };

  const formHook = useForm({
    initialValues,
    validationSchema: abortionSchema,
    onSubmit: handleOnSubmit
  });

  const handleGoBack = () => {
    setTypeReproductiveCheck(null);
  };

  return (
    <>
      <FlexBox alignItems='center' marginBottom='2rem'>
        <Button className='go_back' onClick={handleGoBack}>
          <i className='mdi mdi-chevron-left' />
        </Button>
        <h5>{editReproduction ? 'Modificar' : 'Nuevo'} Aborto</h5>
      </FlexBox>
      <form onSubmit={formHook.handleSubmit}>
        <Grid cols={2}>
          <FormGroup>
            <DatePicker
              label='Fecha'
              name='date'
              value={formHook.values.date}
              onChange={formHook.handleChange}
              error={formHook.errors.date}
            />
          </FormGroup>
          <FormGroup>
            <Select
              name='sex'
              label='Sexo'
              value={formHook.values.sex}
              onChange={formHook.handleChange}
              error={formHook.errors.sex}
              options={sexOptions}
            />
          </FormGroup>
        </Grid>
        <FormGroup>
          <Field
            type='textarea'
            label='Notas'
            name='notes'
            value={formHook.values.notes}
            onChange={formHook.handleChange}
            error={formHook.errors.notes}
          />
        </FormGroup>
        <FormGroup className='center'>
          <Button type='submit' className='success rounded' loading={loading}>
            {editReproduction ? 'Modificar' : 'Añadir'}
          </Button>
        </FormGroup>
        <FormGroup className='text_center' marginBottom='0'>
          <Button
            className='link'
            onClick={() => setModalReproductionIsOpen(false)}
          >
            Cerrar modal
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  abortions: state.reproduction.abortions,
  editReproduction: state.reproduction.editReproduction
});

const mapDispatchToProps = {
  setAbortions,
  setTypeReproductiveCheck,
  setModalReproductionIsOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(AbortionForm);
