import { createGlobalStyle } from 'styled-components';
import 'normalize.css';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,700&display=swap');

  :root {
    --color-primary: #478bee;
    --color-secondary: #6c757d;
    --color-success: #00aa70;
    --color-info: #17a2b8;
    --color-warning: #ffc107;
    --color-danger: #dc3545;
    --color-light: #f8f9fa;
    --color-cancel: #9ea7b8;
    --color-gray: #7f8fa4;
    --color-gray-light: #f9f9f9;
    --color-dark: #354052;
    --color-teal: #21cb87;
    --container-width: 1366px;
    --header-height: 75px;
    --border-width: 1px;
    --border-color: #eff3f6;
    --border-radius: 0.4rem;
    --font-size-lg: 125%;
    --font-size-sm: 85%;
    --components-height: 38px;
    --components-height-sm: 30px;
  }

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-dark);
    margin: 0;
    line-height: 1.2;
    background-color: #eff0f2;
    transition: transform 0.6s ease;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;

    &.mb {
      margin-bottom: 1rem;
    }
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  h5 {
    font-size: 1.2rem;
  }

  h6 {
    font-size: 1rem;
  }

  a {
    color: var(--color-primary);
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      color: var(--color-dark);
    }
  }

  p {
    margin: 0 0 10px 0;
    line-height: 1.2;
  }

  figure {
    margin: 0;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
    }
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:focus {
      outline: transparent;
    }
  }

  main {
    margin-bottom: 4rem;
  }

  .Toastify__toast {
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
  }
  .Toastify__toast--info {
    background: var(--color-primary);
  }
  .Toastify__toast--success {
    background: var(--color-success);
  }
  .Toastify__toast--warning {
    background: var(--color-warning);
  }
  .Toastify__toast--error {
    background: var(--color-danger);
  }
`;

export default GlobalStyle;
