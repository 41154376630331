import { Axios, ApiUrl } from './config';

export const getBreedsApi = async (query) => {
  const res = await Axios.get(`${ApiUrl}/breeds/${query ? `?${query}` : ''}`);
  return res;
};

export const getBreedApi = async (id) => {
  const res = await Axios.get(`${ApiUrl}/breeds/${id}/`);
  return res;
};

export const createBreedApi = async (data) => {
  const res = await Axios.post(`${ApiUrl}/breeds/`, data);
  return res;
};
