import { Axios, ApiUrl } from './config';

export const getEventsApi = async (query) => {
  const res = await Axios.get(`${ApiUrl}/events/${query ? `${query}` : ''}`);
  return res;
};

export const getEventApi = async (id) => {
  const res = await Axios.get(`${ApiUrl}/events/${id}/`);
  return res;
};

export const createEventApi = async (data) => {
  const res = await Axios.post(`${ApiUrl}/events/`, data);
  return res;
};

export const updateEventApi = async (id, data) => {
  const res = await Axios.put(`${ApiUrl}/events/${id}/`, data);
  return res;
};

export const deleteEventApi = async (id) => {
  const res = await Axios.delete(`${ApiUrl}/events/${id}/`);
  return res;
};
