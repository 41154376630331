import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Modal,
  Alert,
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FlexBox,
  Button,
  Title,
  Spinner
} from '../components/ui';
import AnimalForm from '../components/animals/AnimalForm';
import AnimalCard from '../components/animals/AnimalCard';
import AnimalOnSaleForm from '../components/animals/AnimalOnSaleForm';
import ReproductionPanel from '../components/reproduction/ReproductionPanel';
import HealthPanel from '../components/health/HealthPanel';
import ProductionPanel from '../components/production/ProductionPanel';
import EventsPanel from '../components/events/EventsPanel';
import NotFound from './NotFound';
import { getAnimalApi, deleteAnimalApi } from '../api';

const AnimalDetail = ({ history, breeds, animals }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalSaleIsOpen, setModalSaleIsOpen] = useState(false);
  const [alertDeleteIsOpen, setAlertDeleteIsOpen] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [animal, setAnimal] = useState(null);
  const [menuActive, setMenuActive] = useState('reproduccion');
  const { id } = useParams();

  useEffect(() => {
    if (id) getAnimal();
  }, [id]);

  const getAnimal = async () => {
    try {
      const res = await getAnimalApi(id);
      setAnimal(res.data);
    } catch (error) {
      if (error.response && error.response.status == 404) {
        setNotFound(true);
      } else {
        toast.error(error.message);
        console.error(error);
      }
    }
  };

  const showModal = () => {
    setModalIsOpen(true);
  };

  const showModalSale = () => {
    setModalSaleIsOpen(true);
  };

  const handleOnClick = (menu) => {
    setMenuActive(menu);
  };

  const handleClickDelete = () => {
    setAlertDeleteIsOpen(true);
  };

  const handleConfirmDeleteAnimal = async () => {
    await deleteAnimalApi(animal.uuid);
    history.push('/animales');
  };

  if (notFound) return <NotFound />;

  return (
    <>
      <Container>
        <Card className='sm'>
          <CardHeader history={history}>
            <FlexBox justifyContent='space-between' alignItems='center'>
              <Title Tag='h2'>Detalle de animal</Title>
              <div>
                {animal && animal.status !== 'death' && (
                  <Button
                    className='outline_success rounded'
                    style={{ marginRight: '1rem' }}
                    onClick={showModalSale}
                  >
                    {animal && animal.on_sale
                      ? animal.on_sale.sold
                        ? 'Vendido'
                        : 'En Venta'
                      : 'Poner en venta'}
                  </Button>
                )}

                <Button
                  className='outline_primary rounded'
                  style={{ marginRight: '1rem' }}
                  onClick={showModal}
                >
                  Editar
                </Button>
                <Button
                  className='outline_danger rounded'
                  onClick={handleClickDelete}
                >
                  Eliminar
                </Button>
              </div>
            </FlexBox>
          </CardHeader>
          <CardBody className='lg'>
            {animal ? (
              <AnimalCard animal={animal} />
            ) : (
              <Spinner text='Cargando...' />
            )}
          </CardBody>
          <CardFooter>
            <Button
              className={menuActive === 'reproduccion' ? 'active' : ''}
              onClick={() => handleOnClick('reproduccion')}
            >
              Reproducción
            </Button>
            <Button
              className={menuActive === 'sanidad' ? 'active' : ''}
              onClick={() => handleOnClick('sanidad')}
            >
              Sanidad
            </Button>
            <Button
              className={menuActive === 'produccion' ? 'active' : ''}
              onClick={() => handleOnClick('produccion')}
            >
              Producción
            </Button>
            <Button
              className={menuActive === 'eventos' ? 'active' : ''}
              onClick={() => handleOnClick('eventos')}
            >
              Eventos
            </Button>
          </CardFooter>
        </Card>
        {menuActive === 'reproduccion' && (
          <ReproductionPanel animal={animal} animals={animals} />
        )}
        {menuActive === 'sanidad' && (
          <HealthPanel animal={animal} animals={animals} />
        )}
        {menuActive === 'produccion' && (
          <ProductionPanel animal={animal} animals={animals} />
        )}
        {menuActive === 'eventos' && (
          <EventsPanel animal={animal} animals={animals} />
        )}
      </Container>
      <Modal
        title='Modificar animal'
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      >
        <AnimalForm
          animal={animal}
          breeds={breeds}
          setModalIsOpen={setModalIsOpen}
        />
      </Modal>

      <Modal
        title='Poner en venta'
        isOpen={modalSaleIsOpen}
        setIsOpen={setModalSaleIsOpen}
      >
        <AnimalOnSaleForm
          animal={animal}
          setModalSaleIsOpen={setModalSaleIsOpen}
        />
      </Modal>

      <Alert
        title='¿Estas seguro?'
        isOpen={alertDeleteIsOpen}
        setIsOpen={setAlertDeleteIsOpen}
        onConfirmationChange={handleConfirmDeleteAnimal}
        cancelButton
      >
        <p>
          Vas a eliminar por completo este animal junto con todos sus registros.
        </p>
      </Alert>
    </>
  );
};

const mapStateToProps = (state) => ({
  farm: state.farms.farm,
  animals: state.farms.animals,
  breeds: state.farms.breeds
});

export default connect(mapStateToProps, null)(AnimalDetail);
