import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';

import {
  Grid,
  FormGroup,
  Select,
  DatePicker,
  Field,
  FlexBox,
  Button
} from '../ui';
import {
  setMilk,
  setTypeProduction,
  setModalProductionIsOpen
} from '../../redux/actions/production';
import { createMilkApi, updateMilkApi } from '../../api';
import useForm from '../../hooks/useForm';

const milkSchema = Yup.object().shape({
  date: Yup.date()
    .typeError('Ingresa una fecha válida')
    .required('Fecha requerida'),
  animal: Yup.number().required(),
  time: Yup.string().max(50).required('Jornada requerida'),
  kg_concentrated: Yup.number().typeError('Ingresa los kilos de concentrado'),
  lt_milk: Yup.number().typeError('Ingresa los litros de leche')
});

const timeOptions = [
  { value: 'morning', label: 'Mañana' },
  { value: 'afternon', label: 'Tarde' },
  { value: 'ma', label: 'Mañana y tarde' }
];

const MilkForm = ({
  animal,
  milkList,
  setMilk,
  editProduction,
  setModalProductionIsOpen,
  setTypeProduction
}) => {
  const [loading, setLoading] = useState(false);
  let initialValues = {
    date: editProduction
      ? new Date(moment(editProduction.date).format())
      : new Date(),
    animal: editProduction ? editProduction.animal : animal.id,
    time: editProduction ? editProduction.time : '',
    kg_concentrated: editProduction ? editProduction.kg_concentrated : '',
    lt_milk: editProduction ? editProduction.lt_milk : ''
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      if (!editProduction) {
        const res = await createMilkApi(values);
        setMilk([res.data, ...milkList]);
        toast.success('Registro creado correctamente');
        formHook.resetValues();
      } else {
        const res = await updateMilkApi(editProduction.id, values);
        setMilk(
          milkList.map((i) => {
            if (i.id === editProduction.id) return res.data;
            return i;
          })
        );
        toast.success('Registro modificado correctamente');
      }
      setModalProductionIsOpen(false);
    } catch (error) {
      console.log(error.response);
      toast.error(error);
      setLoading(false);
    }
  };

  const formHook = useForm({
    initialValues,
    validationSchema: milkSchema,
    onSubmit: handleOnSubmit
  });

  const handleGoBack = () => {
    setTypeProduction(null);
  };

  return (
    <>
      <FlexBox alignItems='center' marginBottom='2rem'>
        <Button className='go_back' onClick={handleGoBack}>
          <i className='mdi mdi-chevron-left' />
        </Button>
        <h5>{editProduction && 'Modificar '}Producción de leche</h5>
      </FlexBox>
      <form onSubmit={formHook.handleSubmit}>
        <FormGroup>
          <DatePicker
            label='Fecha'
            name='date'
            value={formHook.values.date}
            onChange={formHook.handleChange}
            error={formHook.errors.date}
          />
        </FormGroup>
        <FormGroup>
          <Select
            name='time'
            label='Jornada'
            value={formHook.values.time}
            onChange={formHook.handleChange}
            error={formHook.errors.time}
            options={timeOptions}
          />
        </FormGroup>
        <Grid cols={2}>
          <FormGroup>
            <Field
              type='number'
              label='Kilos de concentrado'
              name='kg_concentrated'
              value={formHook.values.kg_concentrated}
              onChange={formHook.handleChange}
              error={formHook.errors.kg_concentrated}
            />
          </FormGroup>
          <FormGroup>
            <Field
              type='number'
              label='Litros de leche'
              name='lt_milk'
              value={formHook.values.lt_milk}
              onChange={formHook.handleChange}
              error={formHook.errors.lt_milk}
            />
          </FormGroup>
        </Grid>
        <FormGroup className='center'>
          <Button type='submit' className='success rounded' loading={loading}>
            {editProduction ? 'Modificar' : 'Añadir'}
          </Button>
        </FormGroup>
        <FormGroup className='text_center' marginBottom='0'>
          <Button
            className='link'
            onClick={() => setModalProductionIsOpen(false)}
          >
            Cerrar modal
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  milkList: state.production.milk,
  editProduction: state.production.editProduction
});

const mapDispatchToProps = {
  setMilk,
  setTypeProduction,
  setModalProductionIsOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(MilkForm);
