import React from 'react';
import styled from 'styled-components';

import vars from './variables';
import Spinner from './Spinner';

const Button = ({ children, type, disabled, loading, ...props }) => {
  return (
    <Wrapper type={type || 'button'} disabled={loading || disabled} {...props}>
      {loading ? <Spinner /> : children}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  font-size: 0.9rem;
  padding: 0.3em 3em;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: var(--components-height);
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    transform 0.15s ease-in-out;
  cursor: pointer;

  &:focus {
    outline: transparent;
  }

  &:active {
    transform: scale(0.96);
  }

  &.sm {
    font-size: 0.7rem;
    padding: 0.5em 2em;
    height: var(--components-height-sm);
  }

  &.fluid {
    display: block;
    width: 100%;
  }

  &.rounded {
    border-radius: 10em;
  }

  &:disabled {
    opacity: 0.65;

    &:active {
      transform: none;
    }
  }

  &.go_back {
    height: 35px;
    width: 35px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: 0;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    margin-right: 1rem;

    i {
      font-size: 2em;
      line-height: 35px;
    }

    &:hover {
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    }
  }

  &.primary {
    color: white;
    background-color: var(--color-primary);

    &:hover:not(:disabled) {
      color: var(--color-primary);
      border-color: var(--color-primary);
      background-color: white;
    }
  }

  &.outline_primary {
    color: var(--color-primary);
    border-color: var(--color-primary);
    background-color: transparent;

    &:hover:not(:disabled) {
      background-color: rgba(71, 139, 238, 0.1);
    }
  }

  &.success {
    color: white;
    background-color: var(--color-success);

    &:hover:not(:disabled) {
      color: var(--color-success);
      border-color: var(--color-success);
      background-color: white;
    }
  }

  &.outline_success {
    color: var(--color-success);
    border-color: var(--color-success);
    background-color: transparent;

    &:hover:not(:disabled) {
      background-color: rgba(0, 170, 112, 0.1);
    }
  }

  &.warning {
    color: white;
    background-color: var(--color-warning);

    &:hover:not(:disabled) {
      color: var(--color-warning);
      border-color: var(--color-warning);
      background-color: white;
    }
  }

  &.outline_warning {
    color: var(--color-warning);
    border-color: var(--color-warning);
    background-color: transparent;

    &:hover:not(:disabled) {
      background-color: rgba(255, 193, 7, 0.1);
    }
  }

  &.danger {
    color: white;
    background-color: var(--color-danger);

    &:hover:not(:disabled) {
      color: var(--color-danger);
      border-color: var(--color-danger);
      background-color: white;
    }
  }

  &.outline_danger {
    color: var(--color-danger);
    border-color: var(--color-danger);
    background-color: transparent;

    &:hover:not(:disabled) {
      background-color: rgba(220, 53, 69, 0.1);
    }
  }

  &.link {
    color: var(--color-primary);
    background-color: transparent;
    text-decoration: underline;

    &:hover:not(:disabled) {
      text-decoration: none;
    }
  }

  &.link_danger {
    color: var(--color-danger);
    background-color: transparent;
    text-decoration: underline;

    &:hover:not(:disabled) {
      text-decoration: none;
    }
  }

  &.cancel {
    color: var(--color-cancel);
    border-color: var(--color-cancel);
    background-color: transparent;

    &:hover:not(:disabled) {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 30px inset;
    }
  }

  @media (max-width: ${vars.downMd}) {
    font-size: 1rem * 0.9;
  }

  @media (max-width: ${vars.downSm}) {
    font-size: 1rem * 0.8;
  }
`;

export default Button;
