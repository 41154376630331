import React from 'react';
import { connect } from 'react-redux';

import {
  Card,
  Filters,
  CardBody,
  Group,
  GridDefault,
  Title,
  Button,
  FormGroup,
  Input
} from '../ui';
import {
  setEditReproduction,
  setTypeReproductiveCheck,
  setModalReproductionIsOpen
} from '../../redux/actions/reproduction';
import ReproductionModal from './ReproductionModal';
import AbortionList from './AbortionList';
import BirthList from './BirthList';
import CheckupList from './CheckupList';
import InseminationList from './InseminationList';

const ReproductionPanel = ({
  animals,
  animal,
  setEditReproduction,
  setTypeReproductiveCheck,
  setModalReproductionIsOpen
}) => {
  const handleClickAdd = () => {
    setTypeReproductiveCheck(null);
    setEditReproduction(null);
    setModalReproductionIsOpen(true);
  };

  return (
    <>
      <GridDefault>
        <Card>
          <CardBody>
            <Group marginBottom={2}>
              <Title Tag='h4' margin='md'>
                Estado reproductivo
              </Title>
              <Button
                className='success fluid rounded'
                onClick={handleClickAdd}
              >
                + Agregar
              </Button>
            </Group>

            <div>
              <FormGroup>
                <Input type='text' placeholder='Buscar' />
              </FormGroup>

              <Filters>
                <h6 className='mb'>FILTRAR</h6>
                <ul>
                  <li>
                    <a>
                      <i className='mdi mdi-eye'></i> Todo
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className='mdi mdi-eye'></i> Partos
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className='mdi mdi-eye'></i> Inseminaciones
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className='mdi mdi-eye'></i> Chequeo
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className='mdi mdi-eye'></i> Abortos
                    </a>
                  </li>
                </ul>
              </Filters>
            </div>
          </CardBody>
        </Card>

        {animal && (
          <div>
            <InseminationList animal={animal} />
            <BirthList animal={animal} />
            <CheckupList animal={animal} />
            <AbortionList animal={animal} />
          </div>
        )}
      </GridDefault>
      <ReproductionModal animals={animals} animal={animal} />
    </>
  );
};

const mapDispatchToProps = {
  setEditReproduction,
  setTypeReproductiveCheck,
  setModalReproductionIsOpen
};

export default connect(null, mapDispatchToProps)(ReproductionPanel);
