import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';

import {
  Grid,
  FormGroup,
  Select,
  DatePicker,
  Field,
  Checkbox,
  FlexBox,
  Button
} from '../ui';
import { setEvents, setModalEventIsOpen } from '../../redux/actions/events';
import { createEventApi, updateEventApi } from '../../api';
import useForm from '../../hooks/useForm';

const eventSchema = Yup.object().shape({
  date: Yup.date()
    .typeError('Ingresa una fecha válida')
    .required('Fecha requerida'),
  animal: Yup.number().required(),
  name: Yup.string().required('Ingrese el nombre del evento'),
  repeat: Yup.number().required(),
  is_task: Yup.boolean(),
  notes: Yup.string()
});

const EventForm = ({
  animal,
  events,
  setEvents,
  editEvent,
  setModalEventIsOpen
}) => {
  const [loading, setLoading] = useState(false);
  let initialValues = {
    date: editEvent ? new Date(moment(editEvent.date).format()) : new Date(),
    animal: editEvent ? editEvent.animal : animal.id,
    name: editEvent ? editEvent.name : '',
    repeat: editEvent ? editEvent.repeat : 0,
    is_task: editEvent ? editEvent.is_task : false,
    notes: editEvent ? editEvent.notes : ''
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      if (!editEvent) {
        const res = await createEventApi(values);
        setEvents([res.data, ...events]);
        toast.success('Registro creado correctamente');
        formHook.resetValues();
      } else {
        const res = await updateEventApi(editEvent.id, values);
        setEvents(
          events.map((i) => {
            if (i.id === editEvent.id) return res.data;
            return i;
          })
        );
        toast.success('Registro modificado correctamente');
      }
      setModalEventIsOpen(false);
    } catch (error) {
      console.log(error.response);
      toast.error(error);
      setLoading(false);
    }
  };

  const formHook = useForm({
    initialValues,
    validationSchema: eventSchema,
    onSubmit: handleOnSubmit
  });

  return (
    <>
      <FlexBox alignItems='center' marginBottom='2rem'>
        <h5>{editEvent && 'Modificar '}Registro de evento</h5>
      </FlexBox>
      <form onSubmit={formHook.handleSubmit}>
        <FormGroup>
          <DatePicker
            label='Fecha'
            name='date'
            value={formHook.values.date}
            onChange={formHook.handleChange}
            error={formHook.errors.date}
          />
        </FormGroup>
        <FormGroup>
          <Field
            type='text'
            label='Nombre del evento'
            name='name'
            value={formHook.values.name}
            onChange={formHook.handleChange}
            error={formHook.errors.name}
          />
        </FormGroup>
        <Grid cols={2}>
          <FormGroup>
            <Field
              type='number'
              label='Repetir cada cuántos días'
              name='repeat'
              value={formHook.values.repeat}
              onChange={formHook.handleChange}
              error={formHook.errors.repeat}
            />
          </FormGroup>
          <FormGroup>
            <Checkbox
              name='is_task'
              label='Crear como tarea'
              value={formHook.values.is_task}
              onChange={formHook.handleChange}
              error={formHook.errors.is_task}
            />
          </FormGroup>
        </Grid>
        <FormGroup>
          <Field
            type='textarea'
            label='Notas'
            name='notes'
            value={formHook.values.notes}
            onChange={formHook.handleChange}
            error={formHook.errors.notes}
          />
        </FormGroup>
        <FormGroup className='center'>
          <Button type='submit' className='success rounded' loading={loading}>
            {editEvent ? 'Modificar' : 'Añadir'}
          </Button>
        </FormGroup>
        <FormGroup className='text_center' marginBottom='0'>
          <Button className='link' onClick={() => setModalEventIsOpen(false)}>
            Cerrar modal
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  events: state.events.events,
  editEvent: state.events.editEvent
});

const mapDispatchToProps = {
  setEvents,
  setModalEventIsOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);
