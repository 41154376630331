import { useState, useEffect } from 'react';

const useValidateForm = ({ initialValues, validationSchema, onSubmit }) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [hasErrors, setHasErrors] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0;
      setHasErrors(!noErrors);

      if (noErrors) {
        onSubmit(values, isSubmitting);
      }
      setIsSubmitting(false);
    }
  }, [errors]);

  const handleChange = (e) => {
    const target = e.target;
    let name = target.name;
    let value = target.value;

    if (target.type === 'checkbox') {
      value = target.checked;
    }

    if (name.includes('.')) {
      const splitArray = name.split('.');
      name = splitArray[0];
      const subname = splitArray[1];
      value = { ...values[name], [subname]: value };
    }

    setValues({
      ...values,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    validationSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        setErrors({});
      })
      .catch((errors) => {
        let errorList = {};
        errors.inner.map((err) => {
          if (err.path.includes('.')) {
            const splitArray = err.path.split('.');
            const name = splitArray[0];
            const subname = splitArray[1];
            errorList[name] = { ...errors[name], [subname]: err.message };
          } else {
            errorList[err.path] = err.message;
          }
          return err;
        });
        setErrors(errorList);
      });

    setIsSubmitting(formData);
  };

  const resetValues = () => {
    setValues(initialValues);
  };

  return {
    values,
    errors,
    hasErrors,
    isSubmitting,
    handleChange,
    handleSubmit,
    resetValues
  };
};

export default useValidateForm;
