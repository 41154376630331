import React from 'react';
import styled from 'styled-components';

import formatBytes from '../utils/formatBytes';

const MediaCard = ({ file, onClick, selectedFile }) => {
  return (
    <Card
      onClick={() => onClick(file)}
      className={selectedFile && selectedFile.id === file.id ? 'active' : ''}
    >
      <figure>
        <img src={file.file} alt={file.name} />
      </figure>
      <div>
        <h5>
          {file.name}
          {file.ext}
        </h5>
        <FileInfo>
          <span>{file.ext.replace('.', '')}</span> -{' '}
          <span>
            {file.width} x {file.height}
          </span>{' '}
          - <span>{formatBytes(file.size)}</span>
        </FileInfo>
      </div>
    </Card>
  );
};

const Card = styled.div`
  width: 192.5px;
  font-size: 0.8rem;
  font-family: $ff-default;
  line-height: 1.2;
  letter-spacing: 0;
  text-align: left;
  border: 2px solid transparent;

  &.active {
    border-color: red;
  }

  figure {
    margin-bottom: 0.5rem;
    img {
      max-width: 100%;
    }
  }

  h5 {
    font-size: 0.8rem;
    font-family: $ff-default-medium;
    line-height: 1.2;
    letter-spacing: 0;
    margin-bottom: 0.3rem;
  }
`;

const FileInfo = styled.div`
  span {
    color: var(--color-gray);
    font-size: 0.7rem;

    &:first-child {
      text-transform: uppercase;
    }
  }
`;

export default MediaCard;
