import React from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';

const ScreenLoading = ({ text }) => {
  return (
    <Wrapper>
      <Content>
        <Spinner />
        <Text>{text || 'Cargando...'}</Text>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const Content = styled.div`
  width: 200px;
  text-align: center;
`;

const Text = styled.div`
  font-size: 0.9rem;
`;

export default ScreenLoading;
