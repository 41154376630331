import { Axios, ApiUrl } from './config';

// Milk
export const getMilkListApi = async (query) => {
  const res = await Axios.get(
    `${ApiUrl}/production/milk/${query ? `${query}` : ''}`
  );
  return res;
};

export const getMilkApi = async (id) => {
  const res = await Axios.get(`${ApiUrl}/production/milk/${id}/`);
  return res;
};

export const createMilkApi = async (data) => {
  const res = await Axios.post(`${ApiUrl}/production/milk/`, data);
  return res;
};

export const updateMilkApi = async (id, data) => {
  const res = await Axios.put(`${ApiUrl}/production/milk/${id}/`, data);
  return res;
};

export const deleteMilkApi = async (id) => {
  const res = await Axios.delete(`${ApiUrl}/production/milk/${id}/`);
  return res;
};

// Weights
export const getWeightsApi = async (query) => {
  const res = await Axios.get(
    `${ApiUrl}/production/weight/${query ? `${query}` : ''}`
  );
  return res;
};

export const getWeightApi = async (id) => {
  const res = await Axios.get(`${ApiUrl}/production/weight/${id}/`);
  return res;
};

export const createWeightApi = async (data) => {
  const res = await Axios.post(`${ApiUrl}/production/weight/`, data);
  return res;
};

export const updateWeightApi = async (id, data) => {
  const res = await Axios.put(`${ApiUrl}/production/weight/${id}/`, data);
  return res;
};

export const deleteWeightApi = async (id) => {
  const res = await Axios.delete(`${ApiUrl}/production/weight/${id}/`);
  return res;
};
