import React, { useState } from 'react';
import styled from 'styled-components';

const ItemMenu = ({ children }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const openMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMenuVisible(!menuVisible);
  };

  const handleClickMenu = () => {
    setMenuVisible(false);
  };

  return (
    <Menu>
      <button type='button' onClick={openMenu}>
        <i className='mdi mdi-dots-vertical' />
      </button>
      <div
        className={`left_menu ${menuVisible && 'visible'}`}
        onClick={handleClickMenu}
      >
        {children}
      </div>
    </Menu>
  );
};

const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  button {
    padding: 0 0.3rem;
  }

  i {
    font-size: 1.4rem;
    color: var(--color-gray);

    &:hover {
      color: var(--color-dark);
    }
  }

  .left_menu {
    position: absolute;
    top: 75%;
    right: 0;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    overflow: hidden;
    box-shadow: 0 4px 10px 0 rgba(93, 130, 170, 0.21);
    z-index: 10;
    display: none;

    &.visible {
      display: inline-block;
    }

    span {
      display: block;
      cursor: pointer;
      padding: 0.6rem 0.7rem;
      font-size: 0.9rem;

      &:hover {
        background-color: var(--color-light);
      }
    }
  }
`;

export default ItemMenu;
