import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  MenuDots,
  List,
  ListTitle,
  ListHead,
  ListRow,
  ListCol,
  DateFormat,
  Spinner
} from '../ui';
import {
  setBirths,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck,
  setEditReproduction
} from '../../redux/actions/reproduction';
import { getBirthsApi, deleteBirthApi } from '../../api';

const BirthList = ({
  animal,
  births,
  setBirths,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck,
  setEditReproduction
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCheckups();
  }, [animal]);

  const getCheckups = async () => {
    try {
      const res = await getBirthsApi(`?animal=${animal.id}`);
      setBirths(res.data.results);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleClickEdit = (type, data) => {
    setModalReproductionIsOpen(true);
    setTypeReproductiveCheck(type);
    setEditReproduction(data);
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteBirthApi(id);
      if (res.status == 204) {
        setBirths(births.filter((i) => i.id !== id));
        toast.success('Registro eliminado correctamente');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <List>
      <ListTitle>
        <h5>Partos</h5>
      </ListTitle>
      <ListHead>
        <ListCol flex='15%'>Fecha</ListCol>
        <ListCol flex='20%'>Sexo</ListCol>
        <ListCol flex='40%'>Cría</ListCol>
        <ListCol flex='20%'>Código</ListCol>
      </ListHead>
      {loading ? (
        <Spinner />
      ) : births && births.length > 0 ? (
        births.map((birth) => (
          <ListRow key={birth.id}>
            <ListCol flex='15%'>
              <DateFormat date={birth.date} />
            </ListCol>
            <ListCol flex='20%'>{birth.calf_display.sex_display}</ListCol>
            <ListCol flex='40%'>
              <Link to={`/animales/${birth.calf_display.uuid}`}>
                {birth.calf_display.name}
              </Link>
            </ListCol>
            <ListCol flex='20%'>{birth.calf_display.code}</ListCol>
            <ListCol>
              <MenuDots>
                <span onClick={() => handleClickEdit('birth', birth)}>
                  Editar
                </span>
                <span onClick={() => handleDelete(birth.id)}>Eliminar</span>
              </MenuDots>
            </ListCol>
          </ListRow>
        ))
      ) : (
        <ListRow className='no-results'>No hay partos registrados.</ListRow>
      )}
    </List>
  );
};

const mapStateToProps = (state) => ({
  births: state.reproduction.births
});

const mapDispatchToProps = {
  setBirths,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck,
  setEditReproduction
};

export default connect(mapStateToProps, mapDispatchToProps)(BirthList);
