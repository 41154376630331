import { productionActionTypes } from '../actions/production';

const initialState = {
  milk: [],
  weight: [],
  editProduction: null,
  typeProduction: null,
  modalProductionIsOpen: false
};

const productionReducer = (state = initialState, action) => {
  switch (action.type) {
    case productionActionTypes.SET_MILK:
      return { ...state, ...action.payload };
    case productionActionTypes.SET_WEIGHT:
      return { ...state, ...action.payload };
    case productionActionTypes.SET_EDIT_PRODUCTION:
      return { ...state, ...action.payload };
    case productionActionTypes.SET_TYPE_PRODUCTION:
      return { ...state, ...action.payload };
    case productionActionTypes.SET_MODAL_PRODUCTION_IS_OPEN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default productionReducer;
