import { healthActionTypes } from '../actions/health';

const initialState = {
  treatments: [],
  vaccines: [],
  death: null,
  editHealth: null,
  typeHealth: null,
  modalHealthIsOpen: false
};

const healthReducer = (state = initialState, action) => {
  switch (action.type) {
    case healthActionTypes.SET_TREATMENTS:
      return { ...state, ...action.payload };
    case healthActionTypes.SET_VACCINES:
      return { ...state, ...action.payload };
    case healthActionTypes.SET_DEATH:
      return { ...state, ...action.payload };
    case healthActionTypes.SET_EDIT_HEALTH:
      return { ...state, ...action.payload };
    case healthActionTypes.SET_TYPE_HEALTH:
      return { ...state, ...action.payload };
    case healthActionTypes.SET_MODAL_HEALTH_IS_OPEN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default healthReducer;
