import { reproductionActionTypes } from '../actions/reproduction';

const initialState = {
  inseminations: [],
  births: [],
  checkups: [],
  abortions: [],
  editReproduction: null,
  typeReproductiveCheck: null,
  modalReproductionIsOpen: false
};

const reproductionReducer = (state = initialState, action) => {
  switch (action.type) {
    case reproductionActionTypes.SET_INSEMINATIONS:
      return { ...state, ...action.payload };
    case reproductionActionTypes.SET_BIRTHS:
      return { ...state, ...action.payload };
    case reproductionActionTypes.SET_CHECKUPS:
      return { ...state, ...action.payload };
    case reproductionActionTypes.SET_ABORTIONS:
      return { ...state, ...action.payload };
    case reproductionActionTypes.SET_EDIT_REPRODUCTION:
      return { ...state, ...action.payload };
    case reproductionActionTypes.SET_TYPE_REPRODUCTIVE_CHECK:
      return { ...state, ...action.payload };
    case reproductionActionTypes.SET_MODAL_REPRODUCTION_IS_OPEN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reproductionReducer;
