import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from '../pages/Login';

const LogoutRoutes = ({ hookSetUser }) => {
  return (
    <Switch>
      <Route
        exact
        path='/login'
        render={(props) => <Login {...props} hookSetUser={hookSetUser} />}
      />
      <Redirect to='/login' />
    </Switch>
  );
};

export default LogoutRoutes;
