import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  setTypeHealth,
  setEditHealth,
  setModalHealthIsOpen
} from '../../redux/actions/health';
import { Grid, Modal, Card } from '../ui';
import TreatmentForm from './TreatmentForm';
import VaccineForm from './VaccineForm';
import DeathForm from './DeathForm';

const HealthModal = ({
  animals,
  animal,
  typeHealth,
  setTypeHealth,
  modalHealthIsOpen,
  setModalHealthIsOpen,
  setEditHealth
}) => {
  const handleClickType = (item) => {
    setTypeHealth(item);
  };

  const onCloseModalChange = () => {
    setTypeHealth(null);
    setEditHealth(null);
  };

  return (
    <Modal
      title='Chequeo veterinario'
      size='md'
      isOpen={modalHealthIsOpen}
      setIsOpen={setModalHealthIsOpen}
      onClose={onCloseModalChange}
    >
      {!typeHealth ? (
        <GridItems handleClickType={handleClickType} />
      ) : (
        <>
          {typeHealth === 'treatment' && (
            <TreatmentForm animal={animal} animals={animals} />
          )}
          {typeHealth === 'vaccine' && (
            <VaccineForm animal={animal} animals={animals} />
          )}
          {typeHealth === 'death' && (
            <DeathForm animal={animal} animals={animals} />
          )}
        </>
      )}
    </Modal>
  );
};

const GridItems = ({ handleClickType }) => {
  return (
    <Grid cols='3'>
      <Card>
        <ItemLink color='#ffc107' onClick={() => handleClickType('treatment')}>
          <i className='mdi mdi-medical-bag'></i>
          <h5>Tratamiento</h5>
        </ItemLink>
      </Card>
      <Card>
        <ItemLink color='#478bee' onClick={() => handleClickType('vaccine')}>
          <i className='mdi mdi-needle'></i>
          <h5>Vacunar</h5>
        </ItemLink>
      </Card>
      <Card>
        <ItemLink color='#dc3545' onClick={() => handleClickType('death')}>
          <i className='mdi mdi-cow'></i>
          <h5>Animal Fallecido</h5>
        </ItemLink>
      </Card>
    </Grid>
  );
};

const ItemLink = styled.div`
  text-align: center;
  cursor: pointer;
  padding: 1rem 1.6rem;
  border-radius: var(--border-radius);

  &:hover {
    background-color: var(--color-light);
  }

  i {
    font-size: 5rem;
    display: block;
    margin-bottom: 1rem;
    ${({ color }) => color && `color: ${color};`}
  }
`;

const mapStateToProps = (state) => ({
  typeHealth: state.health.typeHealth,
  modalHealthIsOpen: state.health.modalHealthIsOpen
});

const mapDispatchToProps = {
  setTypeHealth,
  setEditHealth,
  setModalHealthIsOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthModal);
