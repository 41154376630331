import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { toast } from 'react-toastify';

import { setBreeds, setAnimals } from '../redux/actions/farms';
import { getAnimalsApi, getBreedsApi } from '../api';
import { ScreenLoading } from '../components/ui';
import Home from '../pages/Home';
import Animals from '../pages/Animals';
import AnimalDetail from '../pages/AnimalDetail';
import NotFound from '../pages/NotFound';

const LoginRoutes = ({ setBreeds, setAnimals }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBreeds();
  }, []);

  const getBreeds = async () => {
    try {
      const res = await getBreedsApi();
      setBreeds(
        res.data.results.map((breed) => ({
          value: breed.id,
          label: breed.name
        }))
      );
      getAnimals();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const getAnimals = async () => {
    try {
      const res = await getAnimalsApi();
      setAnimals(
        res.data.results.filter((animal) => animal.status !== 'death')
      );
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  };

  if (loading) {
    return <ScreenLoading />;
  }

  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/animales' component={Animals} />
      <Route exact path='/animales/:id' component={AnimalDetail} />
      <Route component={NotFound} />
    </Switch>
  );
};

const mapDispatchToProps = {
  setBreeds,
  setAnimals
};

export default connect(null, mapDispatchToProps)(LoginRoutes);
