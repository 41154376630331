import styled from 'styled-components';
import vars from './variables';

const sizes = { sm: '500px', md: '700px', lg: '900px' };

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${({ size }) => (size ? sizes[size] : 'var(--container-width)')};

  @media (max-width: ${vars.downXxl}) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ cols }) => (cols ? cols : 2)}, 1fr);
  column-gap: 1.2rem;
  align-items: flex-start;

  @media (max-width: ${vars.downMd}) {
    grid-template-columns: 1fr;
  }
`;

export const GridDefault = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  column-gap: 1.2rem;
  align-items: flex-start;

  @media (max-width: ${vars.downMd}) {
    grid-template-columns: 1fr;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'flex-start'};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'flex-start')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : '0rem'};
`;

export const Group = styled.div`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}rem` : '1rem'};

  &.text_center {
    text-align: center;
  }

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
