import { animalsActionTypes } from '../actions/farms';

const initialState = {
  farm: null,
  breeds: [],
  animals: []
};

const animalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case animalsActionTypes.SET_FARM:
      return { ...state, ...action.payload };
    case animalsActionTypes.SET_BREEDS:
      return { ...state, ...action.payload };
    case animalsActionTypes.SET_ANIMALS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default animalsReducer;
