export const reproductionActionTypes = {
  SET_INSEMINATIONS: 'SET_INSEMINATIONS',
  SET_BIRTHS: 'SET_BIRTHS',
  SET_CHECKUPS: 'SET_CHECKUPS',
  SET_ABORTIONS: 'SET_ABORTIONS',
  SET_EDIT_REPRODUCTION: 'SET_EDIT_REPRODUCTION',
  SET_TYPE_REPRODUCTIVE_CHECK: 'SET_TYPE_REPRODUCTIVE_CHECK',
  SET_MODAL_REPRODUCTION_IS_OPEN: 'SET_MODAL_REPRODUCTION_IS_OPEN'
};

export const setInseminations = (inseminations) => {
  return {
    type: reproductionActionTypes.SET_INSEMINATIONS,
    payload: { inseminations }
  };
};

export const setBirths = (births) => {
  return {
    type: reproductionActionTypes.SET_BIRTHS,
    payload: { births }
  };
};

export const setCheckups = (checkups) => {
  return {
    type: reproductionActionTypes.SET_CHECKUPS,
    payload: { checkups }
  };
};

export const setAbortions = (abortions) => {
  return {
    type: reproductionActionTypes.SET_ABORTIONS,
    payload: { abortions }
  };
};

export const setEditReproduction = (editReproduction) => {
  return {
    type: reproductionActionTypes.SET_EDIT_REPRODUCTION,
    payload: { editReproduction }
  };
};

export const setTypeReproductiveCheck = (typeReproductiveCheck) => {
  return {
    type: reproductionActionTypes.SET_TYPE_REPRODUCTIVE_CHECK,
    payload: { typeReproductiveCheck }
  };
};

export const setModalReproductionIsOpen = (modalReproductionIsOpen) => {
  return {
    type: reproductionActionTypes.SET_MODAL_REPRODUCTION_IS_OPEN,
    payload: { modalReproductionIsOpen }
  };
};
