import { Axios, ApiUrl } from './config';

// Abortion
export const getAbortionsApi = async (query) => {
  const res = await Axios.get(
    `${ApiUrl}/reproduction/abortions/${query ? `${query}` : ''}`
  );
  return res;
};

export const getAbortionApi = async (id) => {
  const res = await Axios.get(`${ApiUrl}/reproduction/abortions/${id}/`);
  return res;
};

export const createAbortionApi = async (data) => {
  const res = await Axios.post(`${ApiUrl}/reproduction/abortions/`, data);
  return res;
};

export const updateAbortionApi = async (id, data) => {
  const res = await Axios.put(`${ApiUrl}/reproduction/abortions/${id}/`, data);
  return res;
};

export const deleteAbortionApi = async (id) => {
  const res = await Axios.delete(`${ApiUrl}/reproduction/abortions/${id}/`);
  return res;
};

// Births
export const getBirthsApi = async (query) => {
  const res = await Axios.get(
    `${ApiUrl}/reproduction/births/${query ? `${query}` : ''}`
  );
  return res;
};

export const getBirthApi = async (id) => {
  const res = await Axios.get(`${ApiUrl}/reproduction/births/${id}/`);
  return res;
};

export const createBirthApi = async (data) => {
  const res = await Axios.post(`${ApiUrl}/reproduction/births/`, data);
  return res;
};

export const updateBirthApi = async (id, data) => {
  const res = await Axios.put(`${ApiUrl}/reproduction/births/${id}/`, data);
  return res;
};

export const deleteBirthApi = async (id) => {
  const res = await Axios.delete(`${ApiUrl}/reproduction/births/${id}/`);
  return res;
};

// Checkups
export const getCheckupsApi = async (query) => {
  const res = await Axios.get(
    `${ApiUrl}/reproduction/checkups/${query ? `${query}` : ''}`
  );
  return res;
};

export const getCheckupApi = async (id) => {
  const res = await Axios.get(`${ApiUrl}/reproduction/checkups/${id}/`);
  return res;
};

export const createCheckupApi = async (data) => {
  const res = await Axios.post(`${ApiUrl}/reproduction/checkups/`, data);
  return res;
};

export const updateCheckupApi = async (id, data) => {
  const res = await Axios.put(`${ApiUrl}/reproduction/checkups/${id}/`, data);
  return res;
};

export const deleteCheckupApi = async (id) => {
  const res = await Axios.delete(`${ApiUrl}/reproduction/checkups/${id}/`);
  return res;
};

// Inseminations
export const getInseminationsApi = async (query) => {
  const res = await Axios.get(
    `${ApiUrl}/reproduction/inseminations/${query ? `${query}` : ''}`
  );
  return res;
};

export const getInseminationApi = async (id) => {
  const res = await Axios.get(`${ApiUrl}/reproduction/inseminations/${id}/`);
  return res;
};

export const createInseminationApi = async (data) => {
  const res = await Axios.post(`${ApiUrl}/reproduction/inseminations/`, data);
  return res;
};

export const updateInseminationApi = async (id, data) => {
  const res = await Axios.put(
    `${ApiUrl}/reproduction/inseminations/${id}/`,
    data
  );
  return res;
};

export const deleteInseminationApi = async (id) => {
  const res = await Axios.delete(`${ApiUrl}/reproduction/inseminations/${id}/`);
  return res;
};
