import React from 'react';
import styled, { keyframes } from 'styled-components';

const Spinner = ({ showText, text, ...props }) => {
  return (
    <Wrapper {...props}>
      <Box>
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
          />
        </svg>
      </Box>
      {showText && <div className='text'>{text || 'Loading data...'}</div>}
    </Wrapper>
  );
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const loading = keyframes`
0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  display: inline-block;

  ${({ center }) =>
    center && 'display: block; text-align: center; width: 100%;'}

  .text {
    font-size: 0.8em;
    animation-name: ${loading};
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: both;
  }
`;

const Box = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  animation-name: ${spin};
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;
`;

export default Spinner;
