export const productionActionTypes = {
  SET_MILK: 'SET_MILK',
  SET_WEIGHT: 'SET_WEIGHT',
  SET_EDIT_PRODUCTION: 'SET_EDIT_PRODUCTION',
  SET_TYPE_PRODUCTION: 'SET_TYPE_PRODUCTION',
  SET_MODAL_PRODUCTION_IS_OPEN: 'SET_MODAL_PRODUCTION_IS_OPEN'
};

export const setMilk = (milk) => {
  return {
    type: productionActionTypes.SET_MILK,
    payload: { milk }
  };
};

export const setWeight = (weight) => {
  return {
    type: productionActionTypes.SET_WEIGHT,
    payload: { weight }
  };
};

export const setEditProduction = (editProduction) => {
  return {
    type: productionActionTypes.SET_EDIT_PRODUCTION,
    payload: { editProduction }
  };
};

export const setTypeProduction = (typeProduction) => {
  return {
    type: productionActionTypes.SET_TYPE_PRODUCTION,
    payload: { typeProduction }
  };
};

export const setModalProductionIsOpen = (modalProductionIsOpen) => {
  return {
    type: productionActionTypes.SET_MODAL_PRODUCTION_IS_OPEN,
    payload: { modalProductionIsOpen }
  };
};
