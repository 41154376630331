import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';

import { FormGroup, DatePicker, Field, FlexBox, Button } from '../ui';
import {
  setVaccines,
  setTypeHealth,
  setModalHealthIsOpen
} from '../../redux/actions/health';
import { createVaccineApi, updateVaccineApi } from '../../api';
import useForm from '../../hooks/useForm';

const vaccineSchema = Yup.object().shape({
  date: Yup.date()
    .typeError('Ingresa una fecha válida')
    .required('Fecha requerida'),
  animal: Yup.number().required(),
  vaccine: Yup.string().max(255).required('Vacuna requerida'),
  disease: Yup.string().max(255),
  notes: Yup.string()
});

const VaccineForm = ({
  animal,
  vaccines,
  setVaccines,
  editHealth,
  setModalHealthIsOpen,
  setTypeHealth
}) => {
  const [loading, setLoading] = useState(false);
  let initialValues = {
    date: editHealth ? new Date(moment(editHealth.date).format()) : new Date(),
    animal: editHealth ? editHealth.animal : animal.id,
    vaccine: editHealth ? editHealth.vaccine : '',
    disease: editHealth ? editHealth.disease : '',
    notes: editHealth ? editHealth.notes : ''
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      if (!editHealth) {
        const res = await createVaccineApi(values);
        setVaccines([res.data, ...vaccines]);
        toast.success('Registro creado correctamente');
        formHook.resetValues();
      } else {
        const res = await updateVaccineApi(editHealth.id, values);
        setVaccines(
          vaccines.map((i) => {
            if (i.id === editHealth.id) return res.data;
            return i;
          })
        );
        toast.success('Registro modificado correctamente');
      }
      setModalHealthIsOpen(false);
    } catch (error) {
      console.log(error.response);
      toast.error(error);
      setLoading(false);
    }
  };

  const formHook = useForm({
    initialValues,
    validationSchema: vaccineSchema,
    onSubmit: handleOnSubmit
  });

  const handleGoBack = () => {
    setTypeHealth(null);
  };

  return (
    <>
      <FlexBox alignItems='center' marginBottom='2rem'>
        <Button className='go_back' onClick={handleGoBack}>
          <i className='mdi mdi-chevron-left' />
        </Button>
        <h5>{editHealth ? 'Modificar' : 'Nueva'} Vacuna</h5>
      </FlexBox>
      <form onSubmit={formHook.handleSubmit}>
        <FormGroup>
          <DatePicker
            label='Fecha'
            name='date'
            value={formHook.values.date}
            onChange={formHook.handleChange}
            error={formHook.errors.date}
          />
        </FormGroup>
        <FormGroup>
          <Field
            type='text'
            label='Vacuna'
            name='vaccine'
            value={formHook.values.vaccine}
            onChange={formHook.handleChange}
            error={formHook.errors.vaccine}
          />
        </FormGroup>
        <FormGroup>
          <Field
            type='text'
            label='Enfermedad a tratar'
            name='disease'
            value={formHook.values.disease}
            onChange={formHook.handleChange}
            error={formHook.errors.disease}
          />
        </FormGroup>
        <FormGroup>
          <Field
            type='textarea'
            label='Notas'
            name='notes'
            value={formHook.values.notes}
            onChange={formHook.handleChange}
            error={formHook.errors.notes}
          />
        </FormGroup>
        <FormGroup className='center'>
          <Button type='submit' className='success rounded' loading={loading}>
            {editHealth ? 'Modificar' : 'Añadir'}
          </Button>
        </FormGroup>
        <FormGroup className='text_center' marginBottom='0'>
          <Button className='link' onClick={() => setModalHealthIsOpen(false)}>
            Cerrar modal
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  vaccines: state.health.vaccines,
  editHealth: state.health.editHealth
});

const mapDispatchToProps = {
  setVaccines,
  setTypeHealth,
  setModalHealthIsOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(VaccineForm);
