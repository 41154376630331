import React from 'react';
import styled from 'styled-components';

const Filters = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  margin-top: 2rem;

  ul {
    li {
      margin-bottom: 0.2rem;

      a {
        color: var(--color-dark);
        font-size: 0.8rem;

        &:hover,
        &.active {
          color: var(--color-primary);

          .mdi {
            color: var(--color-primary);
          }
        }

        .mdi {
          margin-right: 0.2rem;
          color: var(--color-gray);
        }
      }
    }
  }
`;

export default Filters;
