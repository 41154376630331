import React from 'react';
import { connect } from 'react-redux';

import { setEditEvent, setModalEventIsOpen } from '../../redux/actions/events';
import { Modal } from '../ui';
import EventForm from './EventForm';

const EventModal = ({
  animals,
  animal,
  modalEventIsOpen,
  setModalEventIsOpen,
  setEditEvent
}) => {
  const onCloseModalChange = () => {
    setEditEvent(null);
  };

  return (
    <Modal
      title='Producción'
      size='md'
      isOpen={modalEventIsOpen}
      setIsOpen={setModalEventIsOpen}
      onClose={onCloseModalChange}
    >
      <EventForm animal={animal} animals={animals} />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  modalEventIsOpen: state.events.modalEventIsOpen
});

const mapDispatchToProps = {
  setEditEvent,
  setModalEventIsOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(EventModal);
