import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Card, CardBody, DateFormat } from '../ui';

const AnimalItem = ({ animal }) => {
  return (
    <Link to={`/animales/${animal.uuid}`}>
      <div>
        <Card className='sm shadow pointer'>
          <CardBody>
            <Wrapper>
              <div className='content'>
                <figure>
                  {animal && animal.photo && (
                    <img src={animal.photo_display.file} title={animal.name} />
                  )}
                </figure>
                <div className='info'>
                  <h5>
                    {animal.code} - {animal.name}{' '}
                    {animal.on_sale ? (
                      animal.on_sale.sold ? (
                        <span className='on_sale'>(Vendido)</span>
                      ) : (
                        <span className='on_sale'>(En Venta)</span>
                      )
                    ) : (
                      ''
                    )}
                    {animal.deceased && (
                      <span className='death'>(Fallecido)</span>
                    )}
                  </h5>
                  <small>
                    {animal.status_display}{' '}
                    {animal.last_checkup_status &&
                      `- ${animal.last_checkup_status}`}
                  </small>
                </div>
              </div>
              <DataRight>
                <small>
                  <span>Nacimiento</span>{' '}
                  <strong>
                    <DateFormat date={animal.birth} birth />
                  </strong>
                  <br />
                  Edad:{' '}
                  <strong>
                    <DateFormat date={animal.birth} ageString />
                  </strong>
                </small>
              </DataRight>
            </Wrapper>
          </CardBody>
        </Card>
      </div>
    </Link>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .content {
    display: flex;
    align-items: center;
  }

  figure {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--color-gray);
    margin-right: 1rem;
    overflow: hidden;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info {
    flex: 1;

    h5 {
      font-size: 1rem;
      color: var(--color-dark);
      margin-bottom: 0.2rem;

      span {
        font-size: 0.8em;
        margin-left: 0.5rem;
        opacity: 0.5;

        &.on_sale {
          color: var(--color-success);
        }

        &.death {
          color: var(--color-danger);
        }
      }
    }

    small {
      color: var(--color-gray);
    }
  }
`;

const DataRight = styled.div`
  text-align: right;

  small {
    color: var(--color-gray);

    strong {
      font-weight: normal;
      color: var(--color-dark);
    }
  }
`;

export default AnimalItem;
