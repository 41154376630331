import { Axios, ApiUrl } from './config';

export const getFilesApi = async () => {
  const res = await Axios.get(`${ApiUrl}/media-library/`);
  return res;
};

export const createFileApi = async (formData) => {
  const res = await Axios.post(`${ApiUrl}/media-library/`, formData);
  return res;
};
