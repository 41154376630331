import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  MenuDots,
  List,
  ListTitle,
  ListHead,
  ListRow,
  ListCol,
  DateFormat,
  Spinner
} from '../ui';
import {
  setTreatments,
  setModalHealthIsOpen,
  setTypeHealth,
  setEditHealth
} from '../../redux/actions/health';
import { getTreatmentsApi, deleteTreatmentApi } from '../../api';

const TreatmentList = ({
  animal,
  treatments,
  setTreatments,
  setModalHealthIsOpen,
  setTypeHealth,
  setEditHealth
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTreatments();
  }, [animal]);

  const getTreatments = async () => {
    try {
      const res = await getTreatmentsApi(`?animal=${animal.id}`);
      setTreatments(res.data.results);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleClickEdit = (type, data) => {
    setModalHealthIsOpen(true);
    setTypeHealth(type);
    setEditHealth(data);
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteTreatmentApi(id);
      if (res.status == 204) {
        setTreatments(treatments.filter((i) => i.id !== id));
        toast.success('Registro eliminado correctamente');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <List>
      <ListTitle>
        <h5>Tratamientos</h5>
      </ListTitle>
      <ListHead>
        <ListCol flex='15%'>Fecha</ListCol>
        <ListCol flex='40%'>Diagnóstico</ListCol>
        <ListCol flex='40%'>Medicamento</ListCol>
      </ListHead>
      {loading ? (
        <Spinner />
      ) : treatments && treatments.length > 0 ? (
        treatments.map((treatment) => (
          <ListRow key={treatment.id}>
            <ListCol flex='15%'>
              <DateFormat date={treatment.date} />
            </ListCol>
            <ListCol flex='40%'>{treatment.diagnosis}</ListCol>
            <ListCol flex='40%'>{treatment.medicine}</ListCol>
            <ListCol>
              <MenuDots>
                <span onClick={() => handleClickEdit('treatment', treatment)}>
                  Editar
                </span>
                <span onClick={() => handleDelete(treatment.id)}>Eliminar</span>
              </MenuDots>
            </ListCol>
          </ListRow>
        ))
      ) : (
        <ListRow className='no-results'>
          No hay tratamientos registrados.
        </ListRow>
      )}
    </List>
  );
};

const mapStateToProps = (state) => ({
  treatments: state.health.treatments
});

const mapDispatchToProps = {
  setTreatments,
  setModalHealthIsOpen,
  setTypeHealth,
  setEditHealth
};

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentList);
