import { Axios, ApiUrl } from './config';

export const getAnimalsApi = async (query) => {
  const res = await Axios.get(`${ApiUrl}/animals/${query ? `?${query}` : ''}`);
  return res;
};

export const getAnimalApi = async (id) => {
  const res = await Axios.get(`${ApiUrl}/animals/${id}/`);
  return res;
};

export const createAnimalApi = async (data) => {
  const res = await Axios.post(`${ApiUrl}/animals/`, data);
  return res;
};

export const updateAnimalApi = async (id, data) => {
  const res = await Axios.put(`${ApiUrl}/animals/${id}/`, data);
  return res;
};

export const deleteAnimalApi = async (id) => {
  const res = await Axios.delete(`${ApiUrl}/animals/${id}/`);
  return res;
};

// Animal Sale

export const getAnimalsOnSaleApi = async (query) => {
  const res = await Axios.get(
    `${ApiUrl}/animals-on-sale/${query ? `?${query}` : ''}`
  );
  return res;
};

export const getAnimalOnSaleApi = async (id) => {
  const res = await Axios.get(`${ApiUrl}/animals-on-sale/${id}/`);
  return res;
};

export const createAnimalOnSaleApi = async (data) => {
  const res = await Axios.post(`${ApiUrl}/animals-on-sale/`, data);
  return res;
};

export const updateAnimalOnSaleApi = async (id, data) => {
  const res = await Axios.put(`${ApiUrl}/animals-on-sale/${id}/`, data);
  return res;
};

export const deleteAnimalOnSaleApi = async (id) => {
  const res = await Axios.delete(`${ApiUrl}/animals-on-sale/${id}/`);
  return res;
};
