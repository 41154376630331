import { combineReducers } from 'redux';

import auth from './auth';
import farms from './farms';
import reproduction from './reproduction';
import health from './health';
import production from './production';
import events from './events';

const rootReducer = combineReducers({
  auth,
  farms,
  reproduction,
  health,
  production,
  events
});

export default rootReducer;
