import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';

import Button from './Button';

const Modal = (props) => {
  const { children, isOpen, setIsOpen, title, size, footer, onClose } = props;

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress, false);

    return () => {
      document.removeEventListener('keydown', onKeyPress, false);
    };
  }, []);

  const handleOnClose = () => {
    setIsOpen(false);
    if (onClose) onClose();
  };

  const onKeyPress = (e) => {
    if (e.keyCode === 27) {
      setIsOpen(false);
      if (onClose) onClose();
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <Wrapper>
      <div className='overlay' onClick={handleOnClose} />
      <div className={`dialog ${size ? size : ''}`}>
        <div className='content'>
          <div className='header'>
            <h5 className='title'>{title}</h5>
            <button type='button' className='close' onClick={handleOnClose}>
              <i className='mdi mdi-close' />
            </button>
          </div>
          <div className='body'>{children}</div>
          {footer}
        </div>
      </div>
    </Wrapper>,
    document.getElementById('modal')
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 0;
    background: rgba(0, 0, 0, 0.5);
    animation-name: ${fadeIn};
    animation-duration: 0.3s;
    animation-fill-mode: both;
  }

  .dialog {
    width: 100%;
    max-width: 600px;
    margin: 1rem;
    position: relative;
    max-height: calc(100% - 3.5rem);
    display: flex;

    &.md {
      max-width: 800px;
    }

    &.xl {
      max-width: 1100px;
    }
    &.full {
      max-width: 80%;
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: var(--border-radius);
    outline: 0;
    max-height: calc(100vh - 3.5rem);
    overflow: hidden;
    animation-name: ${fadeIn};
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-delay: 0.2s;
  }

  .header {
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 3rem 1rem 1rem;
    border-bottom: 1px solid var(--border-color);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    background-color: #fbfbfb;
    flex-shrink: 0;

    .title {
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
    }

    .close {
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: 0;
      height: 40px;
      overflow: hidden;
      padding: 4px;
      margin-right: 5px;
      margin-top: -20px;
      width: 40px;
      z-index: 2;
      color: var(--color-dark);
      text-shadow: none;
      transition: background-color 0.1s, color 0.1s;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: transparent;

      img {
        display: inline-block;
        height: 32px;
        width: 32px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        background-color: rgba(9, 45, 66, 0.13);
      }
    }
  }

  .body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    overflow-y: auto;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    padding: 1rem;
    border-top: 1px solid var(--border-color);
  }
`;

export default Modal;
