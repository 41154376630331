import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';

import { FormGroup, Select, DatePicker, Field, FlexBox, Button } from '../ui';
import {
  setBirths,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck
} from '../../redux/actions/reproduction';
import { createBirthApi, updateBirthApi } from '../../api';
import useForm from '../../hooks/useForm';

const birthSchema = Yup.object().shape({
  date: Yup.date()
    .typeError('Ingresa una fecha válida.')
    .required('Fecha requerida.'),
  animal: Yup.number().required(),
  calf: Yup.number()
    .typeError('Hijo/Hija es requerido.')
    .required('Hijo/Hija es requerido.'),
  notes: Yup.string()
});

const BirthForm = ({
  animal,
  animals,
  births,
  setBirths,
  editReproduction,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck
}) => {
  const [loading, setLoading] = useState(false);
  let initialValues = {
    date: editReproduction
      ? new Date(moment(editReproduction.date).format())
      : new Date(),
    animal: editReproduction ? editReproduction.animal : animal.id,
    calf: editReproduction ? editReproduction.calf : null,
    notes: editReproduction ? editReproduction.notes : ''
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      if (!editReproduction) {
        const res = await createBirthApi(values);
        setBirths([res.data, ...births]);
        toast.success('Registro creado correctamente');
        formHook.resetValues();
      } else {
        const res = await updateBirthApi(editReproduction.id, values);
        setBirths(
          births.map((i) => {
            if (i.id === editReproduction.id) return res.data;
            return i;
          })
        );
        toast.success('Registro modificado correctamente');
      }
      setModalReproductionIsOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  const formHook = useForm({
    initialValues,
    validationSchema: birthSchema,
    onSubmit: handleOnSubmit
  });

  const calfOptions = () => {
    const filterList = animals.filter((a) => a.id !== animal.id);
    return filterList.map((a) => ({
      value: a.id,
      label: `${a.code} ${a.name}`
    }));
  };

  const handleGoBack = () => {
    setTypeReproductiveCheck(null);
  };

  return (
    <>
      <FlexBox alignItems='center' marginBottom='2rem'>
        <Button className='go_back' onClick={handleGoBack}>
          <i className='mdi mdi-chevron-left' />
        </Button>
        <h5>{editReproduction ? 'Modificar' : 'Nuevo'} Parto</h5>
      </FlexBox>
      <form onSubmit={formHook.handleSubmit}>
        <FormGroup>
          <DatePicker
            label='Fecha'
            name='date'
            value={formHook.values.date}
            onChange={formHook.handleChange}
            error={formHook.errors.date}
          />
        </FormGroup>
        <FormGroup>
          <Select
            name='calf'
            label='Hijo/Hija'
            value={formHook.values.calf}
            onChange={formHook.handleChange}
            error={formHook.errors.calf}
            options={calfOptions()}
          />
        </FormGroup>
        <FormGroup>
          <Field
            type='textarea'
            label='Notas'
            name='notes'
            value={formHook.values.notes}
            onChange={formHook.handleChange}
            error={formHook.errors.notes}
          />
        </FormGroup>
        <FormGroup className='center'>
          <Button type='submit' className='success rounded' loading={loading}>
            {editReproduction ? 'Modificar' : 'Añadir'}
          </Button>
        </FormGroup>
        <FormGroup className='text_center' marginBottom='0'>
          <Button
            className='link'
            onClick={() => setModalReproductionIsOpen(false)}
          >
            Cerrar modal
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  births: state.reproduction.births,
  editReproduction: state.reproduction.editReproduction
});

const mapDispatchToProps = {
  setBirths,
  setModalReproductionIsOpen,
  setTypeReproductiveCheck
};

export default connect(mapStateToProps, mapDispatchToProps)(BirthForm);
