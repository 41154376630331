import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { getAccessToken, deleteAccessToken, getMeApi } from '../api';

const useAuth = () => {
  const [loadingUser, setLoadingUser] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkUserLogin();
  }, []);

  const checkUserLogin = async () => {
    const accessToken = getAccessToken();

    if (!accessToken) {
      setLoadingUser(false);
      return;
    }

    if (accessToken) {
      try {
        const res = await getMeApi(accessToken);
        if (res.status === 200) {
          setUser(res.data);
        } else {
          deleteAccessToken();
          setUser(null);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) deleteAccessToken();
        } else {
          toast.error(error.message);
        }
      }
    }
    setLoadingUser(false);
  };

  return {
    user,
    setUser,
    loadingUser
  };
};

export default useAuth;
