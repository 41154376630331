const Variables = {
  downXs: '413.98px',
  downSm: '573.98px',
  downMd: '767.98px',
  downLg: '991.98px',
  downXl: '1199.98px',
  downXxl: '1539.98px'
};

export default Variables;
