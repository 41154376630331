import React from 'react';

const Title = ({ children, Tag, margin }) => {
  const styles = {
    sm: { marginBottom: '1rem' },
    md: { marginBottom: '2rem' },
    lg: { marginBottom: '3rem' },
    default: { marginBottom: '0' }
  };
  return <Tag style={margin ? styles[margin] : styles.default}>{children}</Tag>;
};

export default Title;
