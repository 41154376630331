import React from 'react';
import styled from 'styled-components';
import vars from '../ui/variables';

import { ItemData, DateFormat, Grid } from '../ui';

const AnimalDetail = ({ animal }) => {
  return (
    <>
      <Header>
        <figure>
          {animal.photo && (
            <img src={animal.photo_display.file} alt={animal.name} />
          )}
          {animal.deceased && <span className='death'>Fallecido</span>}
          {animal.on_sale && animal.on_sale.sold && (
            <span className='sold'>Vendido</span>
          )}
        </figure>
        <Info>
          <h4>
            {animal.code} - {animal.name}
          </h4>
          <Grid cols={3}>
            <div>
              <ItemData>
                <span>Etapa desarrollo</span> {animal.stage}
              </ItemData>
              <ItemData>
                <span>Nacimiento</span> <DateFormat date={animal.birth} birth />
              </ItemData>
              <ItemData>
                <span>Edad</span> {animal.age_text}
              </ItemData>
              <ItemData>
                <span>Raza</span> {animal.breed_display}
              </ItemData>
              {animal.purpose && (
                <ItemData>
                  <span>Propósito</span> {animal.purpose_display}
                </ItemData>
              )}
              <ItemData>
                <span>Estado</span>{' '}
                {animal.status ? animal.status_display : '-'}
              </ItemData>
            </div>

            <div>
              <ItemData>
                <span>Último parto</span>{' '}
                {animal.last_birth_date ? (
                  <DateFormat date={animal.last_birth_date} />
                ) : (
                  '-'
                )}
              </ItemData>
              <ItemData>
                <span>Última monta/inseminación</span>{' '}
                {animal.last_insemination_date ? (
                  <>
                    <DateFormat date={animal.last_insemination_date} />{' '}
                    <small>
                      Hace{' '}
                      <DateFormat
                        date={animal.last_insemination_date}
                        ageString
                      />
                    </small>
                  </>
                ) : (
                  '-'
                )}
              </ItemData>
              <ItemData>
                <span>Fecha estimada del parto</span>{' '}
                {animal.date_to_birth ? (
                  <DateFormat date={animal.date_to_birth} birth />
                ) : (
                  '-'
                )}
              </ItemData>
              <ItemData>
                <span>Días para el parto</span>{' '}
                {animal.days_to_birth ? animal.days_to_birth : '-'}
              </ItemData>
              <ItemData>
                <span>Último chequeo</span>{' '}
                {animal.last_checkup_date ? (
                  <DateFormat date={animal.last_checkup_date} birth />
                ) : (
                  '-'
                )}
              </ItemData>
              <ItemData>
                <span>Días restantes para secar</span>{' '}
                {animal.end_of_lactation_days
                  ? animal.end_of_lactation_days
                  : '-'}
              </ItemData>
            </div>

            <div>
              <ItemData>
                <span>Producción</span>{' '}
                {animal.last_milk ? `${animal.last_milk} litros` : '-'}
              </ItemData>
              <ItemData>
                <span>Estado Reproductivo</span>{' '}
                {animal.last_checkup_status ? animal.last_checkup_status : '-'}
              </ItemData>
              <ItemData>
                <span>Tiempo en producción</span>{' '}
                {animal.production_time ? animal.production_time : '-'}
              </ItemData>
              <ItemData>
                <span>Días abiertos</span>{' '}
                {animal.open_days ? animal.open_days : '-'}
              </ItemData>
            </div>
          </Grid>
        </Info>
      </Header>
    </>
  );
};

const Header = styled.header`
  display: flex;

  figure {
    width: 380px;
    height: 320px;
    margin-right: 2rem;
    border-radius: var(--border-radius);
    background-color: var(--border-color);
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    span {
      font-size: 2rem;
      font-weight: bold;
      width: 100%;
      text-align: center;
      background-color: white;
      color: var(--color-danger);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &.death {
        color: var(--color-danger);
      }

      &.sold {
        color: var(--color-success);
      }
    }
  }

  @media (max-width: ${vars.downMd}) {
    figure {
      width: 200px;
      height: 150px;
    }
  }

  @media (max-width: ${vars.downSm}) {
    display: block;

    figure {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
`;

const Info = styled.div`
  flex: 1;

  h4 {
    margin-bottom: 1rem;
  }
`;

export default AnimalDetail;
