import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import moment from 'moment';

import { setAnimals } from '../../redux/actions/farms';
import {
  createAnimalOnSaleApi,
  updateAnimalOnSaleApi,
  deleteAnimalOnSaleApi
} from '../../api';
import { Alert, FormGroup, Field, DatePicker, Checkbox, Button } from '../ui';
import useForm from '../../hooks/useForm';

const animalOnSaleSchema = Yup.object({
  price: Yup.number().typeError('Ingresa el precio de venta'),
  sold: Yup.boolean(),
  sale_date: Yup.date().typeError('Ingresa una fecha válida').nullable(),
  notes: Yup.string(),
  animal: Yup.number().required()
});

const AnimalOnSaleForm = ({
  animal,
  setModalSaleIsOpen,
  animals,
  setAnimals
}) => {
  const [loading, setLoading] = useState(false);
  const [alertDeleteIsOpen, setAlertDeleteIsOpen] = useState(false);

  let initialValues = {
    price: animal.on_sale ? animal.on_sale.price : '',
    sold: animal.on_sale ? animal.on_sale.sold : false,
    sale_date:
      animal.on_sale && animal.on_sale.sale_date
        ? new Date(moment(animal.on_sale.sale_date).format())
        : null,
    notes: animal.on_sale ? animal.on_sale.notes : '',
    animal: animal.id
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      if (!animal.on_sale) {
        const res = await createAnimalOnSaleApi(values);
        // setAnimals([res.data, ...animals]);
        toast.success('Venta creada correctamente');
        formHook.resetValues();
      } else {
        const res = await updateAnimalOnSaleApi(animal.on_sale.id, values);
        // setAnimals(
        //   animals.map((animal) => {
        //     if (animal.uuid === animal.uuid) return res.data;
        //     return animal;
        //   })
        // );
        toast.success('Venta modificada correctamente');
      }
      setModalSaleIsOpen(false);
    } catch (error) {
      toast.error(JSON.stringify(error.response));
      setLoading(false);
    }
  };

  const formHook = useForm({
    initialValues,
    validationSchema: animalOnSaleSchema,
    onSubmit: handleOnSubmit
  });

  const handleClickDelete = () => {
    setAlertDeleteIsOpen(true);
  };

  const handleConfirmDeleteAnimalOnSale = async () => {
    await deleteAnimalOnSaleApi(animal.on_sale.id);
    setModalSaleIsOpen(false);
  };

  return (
    <>
      <form onSubmit={formHook.handleSubmit}>
        <FormGroup>
          <Field
            type='number'
            label='Precio'
            name='price'
            value={formHook.values.price}
            onChange={formHook.handleChange}
            error={formHook.errors.price}
          />
        </FormGroup>
        <FormGroup>
          <Checkbox
            name='sold'
            label='Vendido'
            value={formHook.values.sold}
            onChange={formHook.handleChange}
            error={formHook.errors.sold}
          />
        </FormGroup>
        <FormGroup>
          <DatePicker
            label='Fecha de venta'
            name='sale_date'
            value={formHook.values.sale_date}
            onChange={formHook.handleChange}
            error={formHook.errors.sale_date}
          />
        </FormGroup>
        <FormGroup>
          <Field
            type='textarea'
            label='Notas'
            name='notes'
            value={formHook.values.notes}
            onChange={formHook.handleChange}
            error={formHook.errors.notes}
          />
        </FormGroup>
        <FormGroup className='center'>
          <Button type='submit' className='success rounded' loading={loading}>
            {animal.on_sale ? 'Modificar' : 'Añadir'}
          </Button>
        </FormGroup>

        {animal.on_sale && (
          <FormGroup className='text_center' marginBottom='0'>
            <Button className='link_danger' onClick={handleClickDelete}>
              {animal.on_sale.sold ? 'Eliminar venta' : 'Dejar de vender'}
            </Button>
          </FormGroup>
        )}

        <FormGroup className='text_center' marginBottom='0'>
          <Button className='link' onClick={() => setModalSaleIsOpen(false)}>
            Cerrar modal
          </Button>
        </FormGroup>
      </form>

      <Alert
        title='¿Estas seguro?'
        isOpen={alertDeleteIsOpen}
        setIsOpen={setAlertDeleteIsOpen}
        onConfirmationChange={handleConfirmDeleteAnimalOnSale}
        cancelButton
      >
        <p>Vas a eliminar el estado "En Venta" de este animal.</p>
      </Alert>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.farms.user,
  farm: state.farms.farm,
  animals: state.farms.animals
});

const mapDispatchToProps = {
  setAnimals
};

export default connect(mapStateToProps, mapDispatchToProps)(AnimalOnSaleForm);
