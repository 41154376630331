import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  MenuDots,
  List,
  ListTitle,
  ListHead,
  ListRow,
  ListCol,
  DateFormat,
  Spinner
} from '../ui';
import {
  setWeight,
  setModalProductionIsOpen,
  setTypeProduction,
  setEditProduction
} from '../../redux/actions/production';
import { getWeightsApi, deleteWeightApi } from '../../api';

const WeightList = ({
  animal,
  weight,
  setWeight,
  setModalProductionIsOpen,
  setTypeProduction,
  setEditProduction
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getWeightList();
  }, [animal]);

  const getWeightList = async () => {
    try {
      const res = await getWeightsApi(`?animal=${animal.id}`);
      setWeight(res.data.results);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleClickEdit = (type, data) => {
    setModalProductionIsOpen(true);
    setTypeProduction(type);
    setEditProduction(data);
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteWeightApi(id);
      if (res.status == 204) {
        setWeight(weight.filter((i) => i.id !== id));
        toast.success('Registro eliminado correctamente');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <List>
      <ListTitle>
        <h5>Registros de peso</h5>
      </ListTitle>
      <ListHead>
        <ListCol flex='15%'>Fecha</ListCol>
        <ListCol flex='35%'>Peso</ListCol>
        <ListCol flex='25%'>Dieta</ListCol>
        <ListCol flex='20%'>Notas</ListCol>
      </ListHead>
      {loading ? (
        <Spinner />
      ) : weight && weight.length > 0 ? (
        weight.map((w) => (
          <ListRow key={w.id}>
            <ListCol flex='15%'>
              <DateFormat date={w.date} />
            </ListCol>
            <ListCol flex='35%'>{w.weight} Kg</ListCol>
            <ListCol flex='25%'>{w.diet_display}</ListCol>
            <ListCol flex='20%'>{w.notes}</ListCol>
            <ListCol>
              <MenuDots>
                <span onClick={() => handleClickEdit('weight', w)}>Editar</span>
                <span onClick={() => handleDelete(w.id)}>Eliminar</span>
              </MenuDots>
            </ListCol>
          </ListRow>
        ))
      ) : (
        <ListRow className='no-results'>No hay peso registrado.</ListRow>
      )}
    </List>
  );
};

const mapStateToProps = (state) => ({
  weight: state.production.weight
});

const mapDispatchToProps = {
  setWeight,
  setModalProductionIsOpen,
  setTypeProduction,
  setEditProduction
};

export default connect(mapStateToProps, mapDispatchToProps)(WeightList);
