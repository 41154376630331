import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  MenuDots,
  List,
  ListTitle,
  ListHead,
  ListRow,
  ListCol,
  DateFormat,
  Spinner
} from '../ui';
import {
  setDeath,
  setModalHealthIsOpen,
  setTypeHealth,
  setEditHealth
} from '../../redux/actions/health';
import { getDeathsApi, deleteDeathApi } from '../../api';

const DeathList = ({
  animal,
  death,
  setDeath,
  setModalHealthIsOpen,
  setTypeHealth,
  setEditHealth
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDeaths();
  }, [animal]);

  const getDeaths = async () => {
    try {
      const res = await getDeathsApi(`?animal=${animal.id}`);
      if (res.data.results.length > 0) {
        setDeath(res.data.results[0]);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleClickEdit = (type, data) => {
    setModalHealthIsOpen(true);
    setTypeHealth(type);
    setEditHealth(data);
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteDeathApi(id);
      if (res.status == 204) {
        setDeath(null);
        toast.success('Registro eliminado correctamente');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <List>
      <ListTitle>
        <h5>Fallecimiento</h5>
      </ListTitle>
      <ListHead>
        <ListCol flex='15%'>Fecha</ListCol>
        <ListCol flex='40%'>Causas</ListCol>
        <ListCol flex='40%'>Notas</ListCol>
      </ListHead>
      {loading ? (
        <Spinner />
      ) : death ? (
        <ListRow key={death.id}>
          <ListCol flex='15%'>
            <DateFormat date={death.date} />
          </ListCol>
          <ListCol flex='40%'>{death.causes}</ListCol>
          <ListCol flex='40%'>{death.notes}</ListCol>
          <ListCol>
            <MenuDots>
              <span onClick={() => handleClickEdit('death', death)}>
                Editar
              </span>
              <span onClick={() => handleDelete(death.id)}>Eliminar</span>
            </MenuDots>
          </ListCol>
        </ListRow>
      ) : (
        <ListRow className='no-results'>
          No hay fallecimiento registrado.
        </ListRow>
      )}
    </List>
  );
};

const mapStateToProps = (state) => ({
  death: state.health.death
});

const mapDispatchToProps = {
  setDeath,
  setModalHealthIsOpen,
  setTypeHealth,
  setEditHealth
};

export default connect(mapStateToProps, mapDispatchToProps)(DeathList);
