import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { setUser } from '../../redux/actions/auth';
import { deleteAccessToken, deleteRefreshToken } from '../../api/auth';
import { Container } from '../ui';

const Header = ({ user, setUser, hookSetUser }) => {
  const handleLogOut = (e) => {
    e.preventDefault();
    deleteAccessToken();
    deleteRefreshToken();
    setUser(null);
    hookSetUser(null);
  };

  return (
    <Wrapper>
      <Container className='container'>
        <LeftMenu>
          <Logo>
            <Link to='/'>
              <h1>Mi Ganadería</h1>
            </Link>
          </Logo>
          {user && (
            <nav>
              <Link to='/'>Inicio</Link>
              <Link to='/animales'>Animales</Link>
              {/* <Link to='/'>Personal</Link>
              <Link to='/'>Estadísticas</Link> */}
            </nav>
          )}
        </LeftMenu>
        <RightMenu>
          {user && (
            <strong>{user.username ? user.username : user.email}</strong>
          )}
          <nav style={{ marginLeft: '1rem' }}>
            {!user ? (
              <Link to='/login'>Ingresar</Link>
            ) : (
              <button type='button' onClick={handleLogOut}>
                <i className='mdi mdi-logout-variant'></i>
              </button>
            )}
          </nav>
        </RightMenu>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  width: 100%;
  background-color: white;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 2rem;

  .container {
    height: var(--header-height);
    max-width: var(--container-width);
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const LeftMenu = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  nav {
    a {
      margin: 0 0.8rem;
      color: #7f8fa4;

      &:hover {
        color: #354052;
      }
    }
  }
`;

const Logo = styled.div`
  margin-right: 0.5rem;

  a {
    color: var(--color-teal);
  }
`;

const RightMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const mapStateToProps = (state) => ({
  user: state.auth.user
});

const mapDispatchToProps = {
  setUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
